import { SharedComponentsProviderTypes } from '@/context/shared-components/shared-components-provider.types';
import { isAviosOpco } from '@/utils/opco-utils';
import { Environment } from '@channel/shared-header-footer';
import dynamic from 'next/dynamic';
import React from 'react';

const SharedComponentsProvider = ({
  cms,
  children,
  opCoId,
  loadingComponent,
}: SharedComponentsProviderTypes) => {
  const SharedComponentsProviderWrapper = dynamic(
    async () => {
      const shared = await import('@channel/shared-header-footer');
      return shared.SharedComponentsProvider;
    },
    {
      ssr: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      loading: () => loadingComponent,
    },
  );

  return isAviosOpco(opCoId) ? (
    <SharedComponentsProviderWrapper
      cms={{
        accessToken: cms.aviosCMSToken,
        environment: cms.sharedHeaderFooterEnvironment as Environment,
      }}
    >
      {children}
    </SharedComponentsProviderWrapper>
  ) : (
    <div data-testid="data-testid">{children}</div>
  );
};

export default SharedComponentsProvider;
