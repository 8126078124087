import classNames from 'classnames';
import { useWindowSize } from 'react-use';
import Button from '@/components/inputs/button';
import { VARIANT as BUTTON_VARIANT } from '@/components/inputs/button/button.types';
import NextImage from '@/components/data-display/image';
import Icon from '@/components/data-display/icon';
import { VARIANT as IconVariant } from '@/components/data-display/icon/icon.types';
import PartnerTile from '@/components/integrated/partner-tile';
import YoutubeEmbed from '@/components/social-media/youtube-embed/youtube-embed';
import { VARIANT as PARTNER_TILE_VARIANT } from '@/components/integrated/partner-tile/partner-tile.types';
import ButtonImage from '@/components/button-image';
import { WINDOW_BREAKPOINT_WIDTH } from '@/types';
import { CustomLink as Link } from '@/components/custom-link';
import type {
  VersatileCardImage as VersatileCardImageType,
  VersatileCardContent as VersatileCardContentType,
  VersatileCardLinks as VersatileCardLinksType,
  VersatileCardCtas as VersatileCardCtasType,
  VersatileCardFavourite as VersatileCardFavouriteType,
  VersatileCardProperties,
  ClickableProperties,
  ClickWrapperProperties,
  ImageButtonProperties,
} from './versatile-card.types';
import { IMAGE_POSITION } from './versatile-card.types';
import styles from './versatile-card.module.scss';

const VersatileCardClickWrapper = ({
  clickableProperties,
  children,
}: ClickWrapperProperties) =>
  clickableProperties ? (
    <Link
      href={clickableProperties.linkPrimary}
      className={styles['versatile-card__full-card-link']}
      title={clickableProperties.linkLabelPrimary}
      aria-label={clickableProperties.linkLabelPrimary}
    >
      {children}
    </Link>
  ) : (
    children
  );

const VersatileCard = ({
  id,
  backgroundColor,
  image,
  youtube,
  content,
  links,
  ctas,
  favourite,
  className = '',
  contentClassName = '',
  ctasClassName = '',
  borderless = false,
  spaceBetweenHeadingAndBody = false,
  buttonsCollection = [],
}: // eslint-disable-next-line sonarjs/cognitive-complexity
VersatileCardProperties) => {
  const { width: innerWidth } = useWindowSize();

  const variantClass = `versatile-card__${image?.position}`;
  const optionalImageWidth = `versatile-card__${image?.position}-image-width-${image?.width}`;
  const videoPosition = youtube ? 'versatile-card__top' : '';
  const backgroundColorStyle = backgroundColor
    ? {
        backgroundColor,
      }
    : {};
  const imageHeight =
    innerWidth > WINDOW_BREAKPOINT_WIDTH.Medium
      ? image?.height
      : image?.mobileHeight;
  const { cardIsPrimaryLink, linkPrimary, linkLabelPrimary } = links ?? {};
  const clickableProperties: ClickableProperties | null =
    cardIsPrimaryLink && linkPrimary && linkLabelPrimary
      ? {
          linkPrimary,
          linkLabelPrimary,
          linkAnalyticsTag: id ?? 'unknown',
        }
      : null;

  const hasLinksCtasOrButtons =
    links?.linkLabelPrimary ||
    links?.linkLabelSecondary ||
    ctas?.imageButtonPrimary?.src ||
    ctas?.imageButtonSecondary?.src ||
    (buttonsCollection && buttonsCollection?.length > 0);

  return (
    <VersatileCardClickWrapper clickableProperties={clickableProperties}>
      <div
        className={classNames(
          styles['versatile-card'],
          styles[variantClass],
          styles[videoPosition],
          styles[optionalImageWidth],
          {
            [styles['versatile-card--borderless']]: borderless,
            [styles['versatile-card--clickable']]: clickableProperties,
          },
          className,
        )}
        id={id}
        style={backgroundColorStyle}
      >
        {youtube?.youtubeID && !image?.src && (
          <YoutubeEmbed
            {...youtube}
            className={styles['versatile-card__youtube']}
          />
        )}
        {(image?.src || image?.optimised?.desktopImageUrl) &&
          !youtube?.youtubeID && (
            <VersatileCardImage
              image={image}
              variantClass={variantClass}
              imageHeight={imageHeight}
            />
          )}
        {favourite && (
          <VersatileCardFavourite
            mechanicId={content.partner?.mechanicId}
            favourite={favourite}
          />
        )}

        <div
          className={classNames(
            styles['versatile-card__content-container'],
            contentClassName,
          )}
          style={
            (image?.position === 'top' || image?.position === 'bottom') &&
            imageHeight
              ? { height: `calc(100% - ${imageHeight}px)` }
              : { height: 'initial' }
          }
        >
          <VersatileCardContent
            content={content}
            spaceBetweenHeadingAndBody={spaceBetweenHeadingAndBody}
          />
          {!clickableProperties && hasLinksCtasOrButtons && (
            <div className={styles['versatile-card__content-container-ctas']}>
              {links && <VersatileCardLinks links={links} />}
              {ctas && (
                <VersatileCardCtas ctas={ctas} className={ctasClassName} />
              )}
              {buttonsCollection && buttonsCollection.length > 0 && (
                <div
                  className={classNames(
                    styles['versatile-card__content-container-buttons'],
                    {
                      [styles[
                        'versatile-card__content-container-buttons--full-width'
                      ]]: ctas?.ctaFullWidth ?? false,
                    },
                    className,
                  )}
                >
                  {buttonsCollection.map(component => (
                    <ButtonImage key={component.id} {...component} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </VersatileCardClickWrapper>
  );
};

const VersatileCardImage = ({
  image,
  variantClass,
  imageHeight,
}: {
  image: VersatileCardImageType;
  variantClass: string;
  imageHeight?: number;
}) => {
  return (
    <div
      className={classNames(
        styles['versatile-card__image-container'],
        styles[`${variantClass}-image-container`],
        {
          [styles['versatile-card__image-container--contained']]:
            image.contained,
          [styles[`${variantClass}-image-container--contained`]]:
            image.contained,
        },
      )}
      style={
        !image.contained &&
        (image.position === IMAGE_POSITION.Top ||
          image?.position === IMAGE_POSITION.Bottom)
          ? { height: `${imageHeight}px` }
          : {}
      }
    >
      {image.optimised?.desktopImageUrl ? (
        <>
          <NextImage
            src={image.optimised.mobileImageUrl}
            alt={image.optimised.description}
            fill
            className={styles['versatile-card__image--mobile']}
          />
          <NextImage
            src={image.optimised?.desktopImageUrl}
            alt={image.optimised.description}
            fill
            className={styles['versatile-card__image--desktop']}
          />
        </>
      ) : (
        <NextImage src={image.src} fill alt={image.alt} />
      )}
    </div>
  );
};

const VersatileCardContent = ({
  content,
  spaceBetweenHeadingAndBody,
}: {
  content: VersatileCardContentType;
  spaceBetweenHeadingAndBody: boolean;
}) => {
  const { width: innerWidth } = useWindowSize();

  const contentKeys: Array<
    keyof Pick<
      VersatileCardContentType,
      | 'headingOne'
      | 'headingTwo'
      | 'headingThree'
      | 'headingFour'
      | 'headingFive'
      | 'body'
      | 'partner'
    >
  > = [
    'headingOne',
    'headingTwo',
    'headingThree',
    'headingFour',
    'headingFive',
    'body',
    'partner',
  ];

  const hasContentToRender = content && contentKeys.some(key => !!content[key]);

  if (!hasContentToRender) return null;

  const textAlign = `versatile-card__content--${content?.textAlign}`;
  const headingColor = `versatile-card__content--colour-${content?.textColor}`;
  const textColor = `versatile-card__content-description--colour-${content?.textColor}`;

  const mobilePartnerTile = innerWidth < WINDOW_BREAKPOINT_WIDTH.Large;

  return (
    <div
      className={classNames(
        styles['versatile-card__content'],
        styles[textAlign],
        styles[headingColor],
        {
          [styles['versatile-card__content--space-between']]:
            spaceBetweenHeadingAndBody,
        },
      )}
    >
      {content?.partner && (
        <PartnerTile
          {...content.partner}
          {...(mobilePartnerTile && {
            variant: PARTNER_TILE_VARIANT.Default,
          })}
          speedyClassName={styles['speedy-logo']}
        />
      )}
      {content?.headingOne && <h1>{content.headingOne}</h1>}
      {content?.headingTwo && <h2>{content.headingTwo}</h2>}
      {content?.headingThree && <h3>{content.headingThree}</h3>}
      {content?.headingFour && <h4>{content.headingFour}</h4>}
      {content?.headingFive && <h5>{content.headingFive}</h5>}
      {content?.body && (
        <div
          className={classNames(
            styles['versatile-card__content-description'],
            styles[textColor],
          )}
          dangerouslySetInnerHTML={{ __html: content.body || '' }}
        />
      )}
    </div>
  );
};

export const VersatileCardFavourite = ({
  mechanicId,
  favourite,
  favouriteClassname,
}: {
  mechanicId?: number;
  favourite: VersatileCardFavouriteType;
  favouriteClassname?: string;
}) => {
  const handleFavouriteClick = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    if (favourite.toggleFavourite)
      favourite.toggleFavourite(
        !favourite.favouriteId,
        mechanicId,
        favourite.favouriteId,
      );
  };

  return favourite.showFavourite ? (
    <Button
      tabIndex={0}
      variant={BUTTON_VARIANT.Unstyled}
      onClick={handleFavouriteClick}
      onKeyDown={handleFavouriteClick}
      className={classNames(
        styles['versatile-card__favourite'],
        favouriteClassname,
      )}
      aria-label={
        favourite.favouriteId
          ? favourite.favouritesLabels?.removeFromFavourites
          : favourite.favouritesLabels?.addToFavourites
      }
      title={
        favourite.favouriteId
          ? favourite.favouritesLabels?.removeFromFavourites
          : favourite.favouritesLabels?.addToFavourites
      }
    >
      {favourite.favouriteId ? (
        <Icon
          variant={IconVariant.HeartFilled}
          className={styles['versatile-card__favourite__icon-filled']}
        />
      ) : (
        <Icon
          variant={IconVariant.Heart}
          className={styles['versatile-card__favourite__icon']}
        />
      )}
    </Button>
  ) : null;
};

const VersatileCardLinks = ({ links }: { links: VersatileCardLinksType }) => {
  return (
    <>
      {links?.linkLabelPrimary && (
        <div
          className={classNames({
            [styles['versatile-card__content-container-link']]:
              links.linkLabelPrimary,
          })}
        >
          <Button
            as="link"
            variant={BUTTON_VARIANT.Text}
            href={links.linkPrimary ?? undefined}
            onClick={links?.linkFunction}
            title={links.linkLabelPrimary}
            target={links.openLinksInNewTab ? '_blank' : '_self'}
          >
            {links.linkLabelPrimary}
          </Button>
        </div>
      )}

      {links?.linkLabelSecondary && (
        <div
          className={classNames(
            styles['versatile-card__content-container-link'],
          )}
        >
          <Button
            as="link"
            variant={BUTTON_VARIANT.Text}
            href={links.linkSecondary ?? undefined}
            onClick={links?.linkFunction}
            title={links.linkLabelSecondary}
            target={links.openLinksInNewTab ? '_blank' : '_self'}
          >
            {links.linkLabelSecondary}
          </Button>
        </div>
      )}
    </>
  );
};

const ImageButton = ({
  button,
  openCtasInNewTab,
}: {
  button: ImageButtonProperties;
  openCtasInNewTab: VersatileCardCtasType['openCtasInNewTab'];
}) => {
  const { alt, url, src, height, width, handleOnClick } = button;
  return (
    <Button
      className={styles['image-button']}
      aria-label={alt}
      as="link"
      target={openCtasInNewTab ? '_blank' : '_self'}
      href={url}
      onClick={() => {
        if (handleOnClick) {
          handleOnClick();
        }
      }}
    >
      <NextImage
        src={src}
        alt={alt}
        height={height}
        overideHeight={`${height}px`}
        width={width}
        className={styles['versatile-card__content-container-buttons__image']}
      />
    </Button>
  );
};

const VersatileCardCtas = ({
  ctas,
  className,
}: {
  ctas: VersatileCardCtasType;
  className?: string;
}) => {
  if (ctas.imageButtonPrimary?.src || ctas.imageButtonSecondary?.src) {
    return (
      <div
        className={classNames(
          styles['versatile-card__content-container-buttons'],
          {
            [styles['versatile-card__content-container-buttons--full-width']]:
              ctas?.ctaFullWidth ?? false,
          },
          className,
        )}
      >
        {ctas?.imageButtonPrimary?.src && (
          <ImageButton
            button={ctas?.imageButtonPrimary}
            openCtasInNewTab={ctas?.openCtasInNewTab}
          />
        )}

        {ctas?.imageButtonSecondary?.src && (
          <ImageButton
            button={ctas?.imageButtonSecondary}
            openCtasInNewTab={ctas?.openCtasInNewTab}
          />
        )}
      </div>
    );
  }

  return null;
};

export default VersatileCard;
