import getEnvironmentVariable from '../../utils/get-environment-variable';

export const mainAudienceData = {
  audience: 'https://api.avios.com/',
  scope: 'openid profile email offline_access',
};

export const SILENT_LOGIN_FLAG_EXPIRY_TIME_IN_SECONDS = 60;

export const userAgentsSubstringToSkipSilentLogin = () =>
  getEnvironmentVariable(
    'COMMA_SEPARATED_USER_AGENTS_TO_SKIP_SILENT_LOGIN',
  ).split(',');
