import Image from 'next/image';
import classNames from 'classnames';
import contentfulLoader from '@/utils/loader';
import { useEffect, useState } from 'react';
import styles from './image.module.scss';
import type { NextImageProperties } from './image.types';

export const CustomImage = ({
  isStorybook,
  src,
  width,
  height,
  fallbackSource = '/images/other/broken-image.png',
  ...rest
}: NextImageProperties) => {
  const [imgSource, setImgSource] = useState(src);

  useEffect(() => {
    setImgSource(src);
  }, [src]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      loader={isStorybook ? undefined : contentfulLoader}
      src={imgSource}
      width={width}
      height={height}
      onError={() => setImgSource(fallbackSource)}
      {...rest}
    />
  );
};

const NextImage = ({
  isLocal,
  src,
  width,
  height,
  className,
  overideHeight,
  isStorybook = false,
  ...rest
}: NextImageProperties) => {
  if (!src) return null;
  const imageSource =
    isLocal || /\bhttps?:\/\//.test(src) ? src : `https:${src}`;

  return (
    <div
      className={classNames(styles['image-container'], className)}
      style={overideHeight ? { height: overideHeight } : {}}
    >
      <CustomImage
        src={imageSource}
        isStorybook={isStorybook}
        width={width}
        height={height}
        {...rest}
      />
    </div>
  );
};

export default NextImage;
