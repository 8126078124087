export type B2BToken = string;

export enum COOKIES {
  AVIOS_SETTINGS = 'avios_settings',
  NEXT_LOCALE = 'NEXT_LOCALE',
  DOWNLOAD_REWARDS_APP = 'DOWNLOAD_REWARDS_APP',
  DOWNLOAD_APP_PROMPT = 'DOWNLOAD_APP_PROMPT',
  FIRST_TIME_USER = 'FIRST_TIME_USER',
  RUNTIME_FLAVOR = 'RUNTIME_FLAVOR',
  HAS_DONE_SILENT_LOGIN = 'HAS_DONE_SILENT_LOGIN',
  HAS_DONE_SILENT_LOGIN_V2 = 'HAS_DONE_SILENT_LOGIN_V2',
  SH_SILENT_AUTH = 'SH_SILENT_AUTH',
  SILENT_LOGIN_RETURN_TO_BASE64 = 'SILENT_LOGIN_RETURN_TO_BASE64',
  LOGOUT_RETURN_TO = 'LOGOUT_RETURN_TO',
}

export enum ORDER_TYPES {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum SORT_TYPES {
  HIGHEST_RATE = 'highest-rate',
  LOWEST_RATE = 'lowest-rate',
  EARN_RATE = 'earn-rate',
  NAME = 'name',
  ONLINE_POPULARITY = 'online-popularity',
}

export enum WINDOW_BREAKPOINT_WIDTH {
  Small = 568,
  Medium = 760,
  Large = 984,
  ExtraLarge = 1272,
  TwoExtraLarge = 1440,
  ThreeExtraLarge = 1921,
}

export const sortMap = {
  [SORT_TYPES.LOWEST_RATE]: {
    sort: SORT_TYPES.EARN_RATE,
    order: ORDER_TYPES.Ascending,
  },
  [SORT_TYPES.HIGHEST_RATE]: {
    sort: SORT_TYPES.EARN_RATE,
    order: ORDER_TYPES.Descending,
  },
  [SORT_TYPES.NAME]: { sort: SORT_TYPES.NAME, order: ORDER_TYPES.Ascending },
  [SORT_TYPES.ONLINE_POPULARITY]: {
    sort: SORT_TYPES.ONLINE_POPULARITY,
    order: ORDER_TYPES.Ascending,
  },
};

export enum DATA_IDS {
  RESET_FILTERS = 'reset-filters',
  LOAD_MORE = 'load-more',
  COUNTRY_SELECTOR = 'country-selector',
  USER_ICON = 'user-icon',
  SHOP_NOW = 'shop-now',
  FOOTER_LINKS = 'footer-links',
  COOKIE_BANNER_ACCEPT = 'cookie-banner-accept',
  COOKIE_BANNER_REJECT = 'cookie-banner-reject',
  RETAILERS_LIST = 'retailers-list',
  RETAILER_NOT_LISTED = 'retailer-not-listed',
  RETAILERS_SEARCH_LIST = 'retailers-search-list',
  RETAILERS_DATES_LIST = 'retailers-dates-list',

  MISSING_AVIOS_ISSUE_TYPE = 'missing-avios-issue-type',
  MISSING_AVIOS_CLAIM_REASON = 'missing-avios-claim-reason',
  MISSING_AVIOS_SUBMIT_ANOTHER_CLAIM = 'missing-avios-submit-another-claim',
  MISSING_AVIOS_BACK_TO_HOMEPAGE = 'missing-avios-back-to-homepage',
  MISSING_AVIOS_GENERIC_ERROR = 'missing-avios-generic-error',
  MISSING_AVIOS_TRANSACTION_DATE_INPUT = 'missing-avios-transaction-date-input',
  MISSING_AVIOS_RETAILER_SELECT = 'missing-avios-retailer-select',
  MISSING_AVIOS_RETAILER_ORDER_SELECT = 'missing-avios-retailer-order-select',
  MISSING_AVIOS_TRANSACTION_AMOUNT_INPUT = 'missing-avios-transaction-amount-input',
  MISSING_AVIOS_DESCRIPTION_INPUT = 'missing-avios-description-input',
  MISSING_AVIOS_FILE_INPUT = 'missing-avios-file-input',
  MISSING_AVIOS_SUBMIT = 'missing-avios-submit',
  MISSING_AVIOS_USER_EMAIL = 'missing-avios-user-email',
  MISSING_AVIOS_SHOW_UPLOAD_SECTION = 'missing-avios-show-upload-section',
  MISSING_AVIOS_TRY_AGAIN = 'missing-avios-try-again',
  MISSING_AVIOS_GO_MY_ACCOUNT = 'missing-avios-go-my-account',
  MISSING_AVIOS_GO_BACK = 'missing-avios-go-back',
  MISSING_AVIOS_GO_NEXT_STEP = 'missing-avios-go-next-step',
  MISSING_AVIOS_CLAIM_AVIOS = 'missing-avios-claim-avios',

  UPLOAD_FILE = 'upload-file',
  DELETE_FILE = 'delete-file',

  SEARCH_TEXT = 'search-text',
  SEARCH_BUTTON = 'search-button',

  MY_ACCOUNT_PURCHASE_HISTORY_VIEW_ALL = 'my-account-purchase-history-view-all',
  MY_ACCOUNT_VISIT_HISTORY_VIEW_ALL = 'my-account-visit-history-view-all',

  PERSONALISED_SECTION_FAVOURITES_TAB = 'personalised-section-favourites-tab',
  PERSONALISED_SECTION_RECENTLY_VISITED_TAB = 'personalised-section-recently-visted-tab',
  PERSONALISED_SECTION_RECENT_TRANSACTIONS_TAB = 'personalised-section-recent-transactions-tab',
  PERSONALISED_SECTION_PENDING_TRANSACTIONS_TAB = 'personalised-section-pending-transactions-tab',

  FILTER_LIST_PARTNER_CATEGORY = 'filter-list-partner-category',
  FILTER_LIST_SORT_SELECTOR = 'filter-list-sort-selector',

  HEADER_LOGO = 'header-logo',
  HEADER_SMALL_LOGO = 'header-small-logo',
  HEADER_USER = 'header-user',
  HEADER_LOGIN = 'header-login',

  MEMBER_DETAILS_MY_ACCOUNT = 'member-details-my-account',
  MEMBER_DETAILS_MISSING_AVIOS = 'member-details-missing-avios',
  MEMBER_DETAILS_QR_CODE = 'member-details-qr-code',
  MEMBER_DETAILS_LOGOUT = 'member-details-logout',

  MODAL_CLOSE = 'modal-close',

  ERROR_BANNER = 'error-banner',

  PURCHASE_HISTORY_FILTER = 'purchase-history-filter',

  SEARCH_MODAL_INPUT = 'search-modal-input',
}

export type OrderTypes = ORDER_TYPES;
export type SortTypes = SORT_TYPES;

export enum SESSION_ERROR_CODES {
  UNAUTHORIZED = 'Unauthorized: Missing session or user',
  FORBIDDEN = 'Forbidden: You do not have access to this resource',
}
