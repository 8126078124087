import { Dispatch, SetStateAction, useState } from 'react';
import { useDebounce } from 'react-use';
import useSWR from 'swr';
import { COLLECTION_MECHANIC } from '../../models/collection-mechanic/collection-mechanic.types';
import fetcher from '../../utils/fetcher';

const useSearch = (
  collection_method?: COLLECTION_MECHANIC | null,
  defaultValue = '',
  disable_clo?: boolean,
): {
  searchResults: Array<{
    name: string;
    url?: string;
    id?: number;
    external_reference?: string;
    logoUrl: string;
    rate?: string;
    wasRate?: string;
    noOfCollectionMethods: number;
  }>;
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  debouncedValue: string;
  isLoading: boolean;
} => {
  const [searchValue, setSearchValue] = useState<string>(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState<string>('');

  const handleDebounce = () => setDebouncedValue(searchValue);
  useDebounce(handleDebounce, 250, [searchValue]);

  const collectionMethod = collection_method
    ? `&collection_method=${collection_method}`
    : '';

  const disableCLO = disable_clo ? `&disable_clo=${disable_clo}` : '';

  const { data: searchResults = [], isLoading } = useSWR(
    () =>
      debouncedValue.length > 1
        ? `/api/search?value=${encodeURIComponent(
            debouncedValue,
          )}${collectionMethod}${disableCLO}`
        : null,
    fetcher,
  );

  return {
    searchResults,
    searchValue,
    setSearchValue,
    isLoading,
    debouncedValue,
  };
};

export default useSearch;
