import { VARIANT } from '@/components/data-display/icon/icon.types';

export type GenericErrorSectionProperties = {
  title: string;
  description: string;
  links: Array<{
    label: string;
    href: string;
    icon?: VARIANT;
  }>;
  backgroundImage: {
    src: string;
    alt: string;
  };
  sectionAriaLabel?: string;
};

export type GenericErrorSectionContentfulResponse = {
  pageErrorCollection: {
    items: Array<{
      title: string;
      description: string;
      sectionAriaLabel: string | null;
      pageImage: {
        url: string;
        title: string;
        description: string | null;
      };
      pageLinksCollection: {
        items: Array<{
          label: string;
          url: string;
          developerRef: string;
        }>;
      };
    }>;
  };
};

export enum GENERIC_ERROR_CODES {
  NO_ACTIVE_SESSION = 'NO_ACTIVE_SESSION',
  INVALID_SESSION = 'INVALID_SESSION',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  CALLBACK_HANDLER_ERROR = 'CALLBACK_HANDLER_ERROR',
}
