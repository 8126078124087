import { formatErrorForLogging } from '../logger/logger';
import { ErrorResponse } from './fetch.types';

export class FetchError extends Error implements ErrorResponse {
  public readonly hasError: boolean;

  public readonly responseInfo: ErrorResponse['responseInfo'];

  public readonly response: ErrorResponse['response'];

  static async create(request: RequestInit, response: Response) {
    const contentType = response.headers?.get('content-type');

    const responseError = contentType?.includes('application/json')
      ? await response.json()
      : {
          message: await response.text(),
        };

    return new FetchError(request, response, responseError);
  }

  private constructor(
    request: RequestInit,
    response: Response,
    responseError: Record<string, unknown>,
  ) {
    const responseErrorMessage =
      typeof responseError.message === 'string' && responseError.message;
    super(
      responseErrorMessage ||
        (Array.isArray(responseError.errors) &&
          responseError.errors.map(error => error.message).join('. ')) ||
        'unknown error',
    );
    // based on https://stackoverflow.com/a/51229776
    Object.setPrototypeOf(this, FetchError.prototype);

    this.hasError = true;
    this.responseInfo = {
      url: response.url,
      status: response.status,
      statusText: response.statusText,
      method: request.method,
      requestBody:
        typeof request.body === 'string'
          ? `${request.body?.toString().slice(0, 50)}...`
          : 'not defined',
    };

    this.response = responseError;
  }

  toJSON() {
    return formatErrorForLogging(this);
  }

  toString() {
    return JSON.stringify(this.toJSON(), undefined, 2);
  }
}
