import logger, { formatErrorForLogging } from '../logger';
import { FetchError } from './fetch.error';
import { MultiTypeReturn, IRequestInit, FetcherResponse } from './fetch.types';

export default class Fetch {
  protected headers: HeadersInit = {};

  constructor(protected baseUrl: string) {}

  private buildURL(
    resource: string,
    parameters?: Record<string, string>,
  ): string {
    const queryParameters = new URLSearchParams(parameters).toString();
    const queryString = queryParameters ? `?${queryParameters}` : '';
    return `${this.baseUrl}${resource}${queryString}`;
  }

  private async makeRequest<T, R = MultiTypeReturn<T>>(
    resource: string,
    init: IRequestInit,
  ): Promise<FetcherResponse<R>> {
    const { headers, params, bodyJSON, plainBody, ...initData } = init;
    const url = this.buildURL(resource, params);

    const options = {
      headers: { ...this.headers, ...headers },
      ...initData,
    };

    if (bodyJSON) {
      options.body = JSON.stringify(bodyJSON);
    }

    // this body type will be used mainly for Collinson API calls
    if (plainBody) {
      options.body = new URLSearchParams(plainBody).toString();
    }

    const response = await fetch(url, options);

    if (response.ok) {
      const successLog = {
        status: response.status,
        statusText: response.statusText,
        url,
      };
      logger.info(successLog);
    }

    if (!response.ok) {
      const error = await FetchError.create(options, response);
      logger.error('Error while performing request', {
        error: formatErrorForLogging(error),
      });
      throw error;
    }
    if ('errors' in response) {
      logger.error('Errors found in response', {
        error: await FetchError.create(options, response),
      });
    }

    const responseBody: R = response.headers
      .get('content-type')
      ?.includes('application/json')
      ? await response.json()
      : { data: await response.text() };

    return {
      ...responseBody,
      hasError: false,
    };
  }

  protected async post<T>(resource: string, init: IRequestInit = {}) {
    try {
      return await this.makeRequest<T>(resource, {
        method: 'POST',
        ...init,
      });
    } catch (error) {
      return error as ReturnType<typeof this.makeRequest<T>>;
    }
  }

  protected async get<T>(resource: string, init: IRequestInit = {}) {
    try {
      return await this.makeRequest<T>(resource, {
        method: 'GET',
        ...init,
      });
    } catch (error) {
      return error as ReturnType<typeof this.makeRequest<T>>;
    }
  }

  protected async delete<T>(resource: string, init: IRequestInit = {}) {
    try {
      return await this.makeRequest<T>(resource, {
        method: 'DELETE',
        ...init,
      });
    } catch (error) {
      return error as T as ReturnType<typeof this.makeRequest<T>>;
    }
  }
}
