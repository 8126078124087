import classNames from 'classnames';
import { CustomLink as Link } from '@/components/custom-link';
import Icon from '@/components/data-display/icon';
import { VARIANT as ICON_VARIANT } from '@/components/data-display/icon/icon.types';
import Loader from '@/components/feedback/loader';
import styles from './button.module.scss';
import type { ButtonBodyProperties, ButtonProperties } from './button.types';
import { VARIANT, SIZE } from './button.types';

const ButtonBody = ({
  isLoading,
  icon,
  leadingIcon,
  children,
  trailingIcon,
  topIcon,
  bottomIcon,
  variant,
  textColor,
  textSize,
  iconColor,
  externalLink,
}: ButtonBodyProperties) => {
  const nonIconOnlyBody = (
    <>
      {leadingIcon && !externalLink && (
        <Icon
          variant={leadingIcon}
          className={classNames(styles[`button__leading-icon`], {
            [styles[`button__leading-icon--${iconColor}`]]:
              iconColor && variant === VARIANT.Custom,
          })}
        />
      )}

      {topIcon && !externalLink && (
        <Icon
          variant={topIcon}
          className={classNames(styles[`button__top-icon`], {
            [styles[`button__top-icon--${iconColor}`]]:
              iconColor && variant === VARIANT.Custom,
          })}
        />
      )}

      {variant === VARIANT.Unstyled ? (
        children
      ) : (
        <span
          className={classNames(styles.button__label, {
            [styles[`button__label--${textColor}`]]:
              textColor && variant === VARIANT.Custom,
            [styles[`button__label--${textSize}`]]:
              textSize && variant === VARIANT.Custom,
          })}
        >
          {children}
        </span>
      )}

      {trailingIcon && !externalLink && (
        <Icon
          variant={trailingIcon}
          className={classNames(styles[`button__trailing-icon`], {
            [styles[`button__trailing-icon--${iconColor}`]]:
              iconColor && variant === VARIANT.Custom,
          })}
        />
      )}

      {bottomIcon && !externalLink && (
        <Icon
          variant={bottomIcon}
          className={classNames(styles[`button__bottom-icon`], {
            [styles[`button__bottom-icon--${iconColor}`]]:
              iconColor && variant === VARIANT.Custom,
          })}
        />
      )}

      {externalLink && (
        <Icon
          variant={ICON_VARIANT.ExternalLink}
          className={styles[`button__external-icon`]}
        />
      )}
    </>
  );

  return (
    <>
      {isLoading && <Loader className={styles.button__loader} />}

      {icon ? (
        <Icon variant={icon} className={styles.button__icon} />
      ) : (
        nonIconOnlyBody
      )}
    </>
  );
};

const Button = ({
  variant = VARIANT.Contained,
  size = SIZE.Medium,
  isLoading = false,
  icon,
  leadingIcon,
  children,
  trailingIcon,
  topIcon,
  bottomIcon,
  className = '',
  dataId,
  iconColor,
  textColor,
  textSize,
  renderAsAnchor = false,
  externalLink = false,
  ...properties
}: ButtonProperties) => {
  const allClassNames = classNames(
    styles.button,
    styles[variant],
    styles[size],
    styles.button__wrapper,
    {
      [styles['button--icon-only']]: icon,
      [styles['button__wrapper--column']]: topIcon ?? bottomIcon,
    },
    className,
  );

  if (
    icon &&
    (leadingIcon || trailingIcon || topIcon || bottomIcon || children)
  ) {
    throw new Error(
      'You cannot pass both icon and leadingIcon, trailingIcon or children props to a button',
    );
  }

  if (properties.as === 'link') {
    const {
      href = '',
      'aria-label': ariaLabel,
      target = '_self',
      title,
      onClick,
    } = properties;

    const isAnchor = href.match('^(http(s?)|/api/auth)') || renderAsAnchor;
    const AnchorOrLink = isAnchor ? 'a' : Link;
    return (
      <AnchorOrLink
        className={allClassNames}
        href={href}
        target={target}
        title={title}
        aria-label={ariaLabel}
        onClick={onClick}
        data-id={dataId}
      >
        <ButtonBody
          icon={icon}
          leadingIcon={leadingIcon}
          trailingIcon={trailingIcon}
          isLoading={isLoading}
          topIcon={topIcon}
          bottomIcon={bottomIcon}
          textColor={textColor}
          iconColor={iconColor}
          textSize={textSize}
          variant={variant}
          externalLink={target === '_blank'}
        >
          {children}
        </ButtonBody>
      </AnchorOrLink>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={properties.disabled || isLoading}
      className={classNames(allClassNames, {
        [styles['button--loading']]: isLoading,
        [styles['button--disabled']]: properties.disabled,
      })}
      data-id={dataId}
      {...properties}
    >
      <ButtonBody
        icon={icon}
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        isLoading={isLoading}
        variant={variant}
        topIcon={topIcon}
        bottomIcon={bottomIcon}
        textColor={textColor}
        iconColor={iconColor}
        textSize={textSize}
        externalLink={externalLink}
      >
        {children}
      </ButtonBody>
    </button>
  );
};

export default Button;
