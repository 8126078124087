export type FeatureTogglesType = string[];

export enum FEATURE_TOGGLE_LIST {
  COOKIEDISABLED = 'COOKIEDISABLED',
  PERSONALISED_SECTION = 'PERSONALISED_SECTION',
  NO_FAVS = 'noFaves',
  CLO = 'CLO',
  AMEX_MGM = 'AMEX_MGM',
  TOP_PICKS = 'TOP_PICKS',
  DOWNLOAD_REWARDS_APP_MODAL = 'DOWNLOAD_REWARDS_APP_MODAL',
  DOWNLOAD_APP_PROMPT = 'DOWNLOAD_APP_PROMPT',
  PDP_BREADCRUMBS = 'PDP_BREADCRUMBS',
  SHOW_QR_CODE = 'SHOW_QR_CODE',
  ONETRUST_COOKIES = 'ONETRUST_COOKIES',
  TEMP_SHARED_FOOTER = 'TEMP_SHARED_FOOTER',
  TEMP_SHARED_HEADER = 'TEMP_SHARED_HEADER',
  TEMP_LOCALE_MODAL = 'TEMP_LOCALE_MODAL',
  TEMP_GPT_IN_SEARCH_RESULTS = 'TEMP_GPT_IN_SEARCH_RESULTS',
  TEMP_TRACK_CLICKS = 'TEMP_TRACK_CLICKS',
  TEMP_PARENT_DOMAIN_COOKIE = 'TEMP_PARENT_DOMAIN_COOKIE',
  TEMP_USE_SSO_ON_PAGE_LOAD = 'TEMP_USE_SSO_ON_PAGE_LOAD',
  TEMP_SEARCH_MODAL = 'TEMP_SEARCH_MODAL',
  TEMP_AVIOS_TIMELINE = 'TEMP_AVIOS_TIMELINE',
  TEMP_AVIOS_ALTO_COMPONENTS = 'TEMP_AVIOS_ALTO_COMPONENTS',
  TEMP_CONSOLIDATED_HOUSEHOLD_AVIOS = 'TEMP_CONSOLIDATED_HOUSEHOLD_AVIOS',
}
