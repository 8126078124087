import { BaseElementProperties } from '../../base.types';
import { VARIANT as ICON_VARIANT } from '../../data-display/icon/icon.types';

export enum VARIANT {
  Contained = 'button--contained',
  Outlined = 'button--outlined',
  Text = 'button--text',
  TextBlock = 'button--text-block',
  Danger = 'button--danger',
  Tertiary = 'button--tertiary',
  Ghost = 'button--ghost',
  Unstyled = 'button--unstyled',
  Custom = 'button--custom',
}

export enum SIZE {
  xSmall = 'button--xsmall',
  Small = 'button--small',
  Medium = 'button--medium',
  Large = 'button--large',
  xLarge = 'button--xlarge',
  xxLarge = 'button--xxlarge',
  xxxLarge = 'button--xxxlarge',
}

export type ButtonBodyProperties = {
  isLoading?: boolean;
  icon?: ICON_VARIANT | null;
  leadingIcon?: ICON_VARIANT | null;
  trailingIcon?: ICON_VARIANT | null;
  topIcon?: ICON_VARIANT | null;
  bottomIcon?: ICON_VARIANT | null;
  children?: React.ReactNode;
  variant?: VARIANT;
  textColor?: 'primary' | 'white' | 'black';
  iconColor?: 'primary' | 'white' | 'black';
  textSize?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  renderAsAnchor?: boolean;
  externalLink?: boolean;
} & BaseElementProperties;

type CoreButtonProperties = {
  variant?: VARIANT;
  size?: SIZE;
  externalLink?: boolean;
} & ButtonBodyProperties;

export type ButtonAsButton = CoreButtonProperties &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    keyof CoreButtonProperties
  > & {
    as?: 'button';
  };

type ButtonAsLink = CoreButtonProperties &
  Omit<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    keyof CoreButtonProperties
  > & {
    as: 'link';
  };

export type ButtonProperties = ButtonAsButton | ButtonAsLink;
