import { toggleModal } from '@/components/utilities/modal';
import { HOTJAR_ID } from '@/constants';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import CookieBanner from '@/modules/cookie-banner';
import Footer from '@/modules/footer';
import Header from '@/modules/header';
import LocaleModal, { LocaleModalProperties } from '@/modules/locale-modal';
import MaintenanceContent from '@/modules/maintenance';
import SearchBar from '@/modules/search-bar';
import TrackClicks from '@/modules/track-clicks';
import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { DESIGN_TOKENS_OPCO_ID } from '@/models/market/market.types';
import DownloadAppPrompt from '@/modules/download-app-prompt';
import DownloadRewardsAppModal from '@/modules/download-rewards-app/download-rewards-app';
import HotJarWidget from '@/modules/hotjar-feedback-widget';
import Loading from '@/modules/loading-screen';
import Notifications from '@/modules/notifications';
import PromotionalModal from '@/modules/promotional-modal';
import QrCodeModal from '@/modules/qr-code-modal';
import { COOKIES } from '@/types';
import { useMetrics } from '@/utils/use-metrics/use-metrics';
import '@alto-avios/alto-tokens/web-ssr/avios/dark.css';
import '@alto-avios/alto-tokens/web-ssr/avios/light.css';
import '@channel/shared-header-footer/style.css';
import { UseMetricsParameters } from '@/utils/use-metrics/use-metrics.types';
import { Button } from '@alto-avios/alto-ui';
import { AppProps } from 'next/app';
import { useAppProperties } from '@/hooks/use-app-properties';
import { ShoppingProviders } from '@/context';
import { Main } from '../../modules/main/main';
import { DynamicDesignTokensArguments } from './_app.types';

const MAX_SCROLL_TO_HASH_ATTEMPTS = 15;

export function scrollToHash() {
  const hashId = window.location.hash;
  if (hashId) {
    const element = document.querySelector(`#${hashId.slice(1)}`);

    if (element) {
      const elementTop = element.getBoundingClientRect().top;
      const topPosition = elementTop + window.scrollY;

      window.scrollTo({
        top: topPosition,
        behavior: 'smooth',
      });
    }
  }
}

const DesignTokens = ({ opCoId }: DynamicDesignTokensArguments) => {
  const ImportedDesignTokens = useMemo(
    () =>
      dynamic(
        async () => {
          const sharedComponentsModules = await import(
            `@alto-avios/alto-tokens/web-ssr/${DESIGN_TOKENS_OPCO_ID[opCoId]}/light.css`
          );
          return sharedComponentsModules.DesignTokens;
        },
        {
          ssr: false,
        },
      ),
    [opCoId],
  );

  return <ImportedDesignTokens />;
};

const MyApp = ({
  Component,
  pageProps,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
AppProps) => {
  const { appProperties, isLoading: appPropertiesIsLoading } =
    useAppProperties();

  const {
    programData,
    maintenancePageProperties,
    featureToggles,
    application_environment,
    qrCodeModalProperties,
    userProperties,
    promotionalModalProperties,
    downloadAppPromptProperties,
    notificationsProperties,
    localeModalProperties,
    clientSideMarket,
    aviosCMSToken,
    sharedHeaderFooterEnvironment,
    headerProperties,
    footerProperties,
    searchBarProperties,
    cookieBannerProperties,
    downloadRewardsAppProperties,
  } = appProperties;

  const [showRewardsAppModal, setShowRewardsAppModal] =
    useState<boolean>(false);
  const [showDownloadAppPrompt, setShowDownloadAppPrompt] =
    useState<boolean>(false);
  const [showLocaleModal, setShowLocaleModal] = useState<boolean>(false);

  const enableCookieBanner =
    cookieBannerProperties &&
    !featureToggles?.includes(FEATURE_TOGGLE_LIST.COOKIEDISABLED);

  const featureToggleTemporaryLocaleModal = featureToggles?.includes(
    FEATURE_TOGGLE_LIST.TEMP_LOCALE_MODAL,
  );
  const showLocaleModalFeature =
    (featureToggleTemporaryLocaleModal &&
      showLocaleModal &&
      localeModalProperties) ??
    false;

  const downloadRewardsAppModalEnabled =
    downloadRewardsAppProperties &&
    featureToggles?.includes(FEATURE_TOGGLE_LIST.DOWNLOAD_REWARDS_APP_MODAL) &&
    (isAndroid || isIOS) &&
    showRewardsAppModal;

  const downloadAppPromptEnabled =
    featureToggles?.includes(FEATURE_TOGGLE_LIST.DOWNLOAD_APP_PROMPT) &&
    (isAndroid || isIOS) &&
    showDownloadAppPrompt &&
    !Cookies.get(COOKIES.DOWNLOAD_APP_PROMPT);

  const qrCodeEnabled =
    featureToggles?.includes(FEATURE_TOGGLE_LIST.SHOW_QR_CODE) &&
    qrCodeModalProperties;

  const trackClickFeature =
    featureToggles?.includes(FEATURE_TOGGLE_LIST.TEMP_TRACK_CLICKS) ?? false;

  const hjid =
    (application_environment && HOTJAR_ID[application_environment]) ?? '';

  const { isLoggedIn, membershipId, balance, daysSinceLastPurchase } =
    userProperties ?? {};

  useMetrics(
    appPropertiesIsLoading
      ? ({
          programData,
          isLoggedIn,
          membershipId,
          balance,
          daysSinceLastPurchase,
          applicationEnvironment: application_environment,
        } as UseMetricsParameters)
      : undefined,
  );

  useEffect(() => {
    setTimeout(() => {
      setShowRewardsAppModal(true);
      setShowDownloadAppPrompt(true);
    }, 100);
  });

  // Check a better way to do the scrollToHash - AEM-4785
  useEffect(() => {
    const hashId = window.location.hash;

    let x = 0;
    const intervalScroll = setInterval(() => {
      scrollToHash();
      x += 1;

      if (
        !hashId ||
        document.querySelector(`#${hashId.slice(1)}`) ||
        x > MAX_SCROLL_TO_HASH_ATTEMPTS
      ) {
        window.clearInterval(intervalScroll);
      }
    }, 200);
  });

  useEffect(() => {
    const firstTimeUser = Cookies.get(COOKIES.FIRST_TIME_USER);
    if (
      firstTimeUser &&
      featureToggleTemporaryLocaleModal &&
      localeModalProperties &&
      localeModalProperties.availableLocales.length > 1
    ) {
      setShowLocaleModal(true);
      toggleModal('locale-modal'); // this will disable the horizontal scrollbar
    }
  }, [
    featureToggleTemporaryLocaleModal,
    showLocaleModal,
    localeModalProperties,
  ]);

  const closeLocaleModal = () => {
    Cookies.remove(COOKIES.FIRST_TIME_USER);
    setShowLocaleModal(false);
  };

  const closePromptHandler = () => {
    setShowDownloadAppPrompt(false);
    Cookies.set(COOKIES.DOWNLOAD_APP_PROMPT, 'shown', { expires: 7 });
  };

  if (
    clientSideMarket &&
    featureToggles &&
    aviosCMSToken &&
    sharedHeaderFooterEnvironment &&
    (!aviosCMSToken || !sharedHeaderFooterEnvironment || !headerProperties)
  ) {
    const earlyMain = (
      <Main Component={Component} pageProps={pageProps} skeletonHeader />
    );

    return (
      <ShoppingProviders
        clientSideMarket={clientSideMarket}
        featureToggles={featureToggles}
        aviosCMSToken={aviosCMSToken}
        sharedHeaderFooterEnvironment={sharedHeaderFooterEnvironment}
        loadingComponent={earlyMain}
      >
        {earlyMain}
      </ShoppingProviders>
    );
  }

  // this should be impossible, but we'll leave it here just in case.
  // all properties should be defined at this point, or else we'd fall
  // into the condition above instead of this one
  if (
    !clientSideMarket ||
    !featureToggles ||
    !aviosCMSToken ||
    !sharedHeaderFooterEnvironment ||
    !headerProperties ||
    !footerProperties
  )
    return null;

  const main = <Main Component={Component} pageProps={pageProps} />;
  return maintenancePageProperties?.isActive ? (
    <MaintenanceContent {...maintenancePageProperties} />
  ) : (
    <ShoppingProviders
      clientSideMarket={clientSideMarket}
      featureToggles={featureToggles}
      aviosCMSToken={aviosCMSToken}
      sharedHeaderFooterEnvironment={sharedHeaderFooterEnvironment}
      loadingComponent={main}
    >
      <Loading />
      {/* the following is needed to prevent NextJs from removing styles it
      thinks are not needed https://aviosgroup.atlassian.net/browse/AEM-5017 
      This was tried, but it still removes styles when navigating whithin the same dynamic route
      https://github.com/vercel/next.js/discussions/64658#discussioncomment-9142291 */}
      <div style={{ display: 'none' }}>
        <Button />
      </div>
      {trackClickFeature && <TrackClicks />}
      <DesignTokens opCoId={clientSideMarket.opCoId} />
      <Header {...headerProperties}>
        {searchBarProperties && <SearchBar {...searchBarProperties} />}
      </Header>
      {main}
      <Footer {...footerProperties} />

      {notificationsProperties && (
        <Notifications {...notificationsProperties} />
      )}
      {enableCookieBanner && <CookieBanner {...cookieBannerProperties} />}
      {hjid && <HotJarWidget hjid={hjid} />}
      {downloadRewardsAppModalEnabled && (
        <DownloadRewardsAppModal {...downloadRewardsAppProperties} />
      )}
      {qrCodeEnabled && <QrCodeModal {...qrCodeModalProperties} />}
      {promotionalModalProperties && !showLocaleModal && (
        <PromotionalModal {...promotionalModalProperties} />
      )}
      {downloadAppPromptEnabled && downloadAppPromptProperties && (
        <DownloadAppPrompt
          {...downloadAppPromptProperties}
          closePrompt={closePromptHandler}
        />
      )}
      {showLocaleModalFeature && (
        <LocaleModal
          {...(localeModalProperties as LocaleModalProperties)}
          label="locale-modal"
          onCloseFunction={closeLocaleModal}
        />
      )}
    </ShoppingProviders>
  );
};

export default MyApp;
