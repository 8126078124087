import Market from '@/models/market';
import { localeModalQuery } from './locale-modal.query';
import ContentfulProvider from '../../providers/contentful/contentful.provider';
import {
  LocaleModalResponse,
  LocalModalHandlerProperties,
} from './locale-modal.types';

export const localeModalHandler = async (
  market: Market,
): Promise<LocalModalHandlerProperties | null> => {
  const contentfulResponse = await new ContentfulProvider(
    market,
  ).makeContentfulRequest<LocaleModalResponse>({
    query: localeModalQuery,
  });

  const { items } = contentfulResponse.data.localeModalCollection;

  const { title, description } = items[0] ?? {};

  const footerLabelsCollection: { [key: string]: string } = {};
  for (const item of contentfulResponse.data.microcopyCollection.items) {
    footerLabelsCollection[item.key] = item.value;
  }

  return {
    title,
    description,
    availableLocales: market.availableLocales,
    currentLocale: market.locale,
    footerLabelsCollection,
  };
};
