import CBRProvider from '../../providers/cbr';
import { ORDER_TYPES, SORT_TYPES } from '../../types';
import MarketDataLoader from '../../utils/market-data-loader';
import { Market } from '../market/market.types';
import buildPartnerPaginatedQuery from './partner-paginated.query';
import buildPartnerQuery from './partner.query';
import {
  CBRMechanicCollectionMethodPartnerResponse,
  CBRPartner,
  CBRPartnerResponse,
  PartnerFilterQuery,
  PartnerQueryFindOptions,
} from './partner.types';

const DEFAULT_OFFSET = 0;
const DEFAULT_LIMIT = 9999;

const fetchData = (market: Market, slugs: readonly string[]) =>
  new CBRProvider(market).makeCBRRequest<CBRPartnerResponse>({
    query: buildPartnerQuery({ slugs }),
    variables: {
      slugs,
    },
  });

const mapResponse = (slugs: readonly string[], data: CBRPartnerResponse) =>
  slugs
    .map(slug => {
      const foundMerchant = data?.merchant?.find(
        merchant => merchant.slug === slug,
      );
      return foundMerchant
        ? Object.freeze(foundMerchant)
        : {
            merchant_id: null,
            mechanics: [],
            merchant_categories: [],
            merchant_primary_categories: [],
            name: '',
            slug: '',
            external_reference: '',
            description: '',
            merchant_images: [],
          };
    })
    .filter(x => !!x);

const batcher = (market: Market) => async (slugs: readonly string[]) => {
  const response = await fetchData(market, slugs);

  return mapResponse(slugs, response.data);
};

const buildOrderByVariable = (
  sort = SORT_TYPES.ONLINE_POPULARITY,
  order = ORDER_TYPES.Ascending,
) => {
  const orderByMechanicId = { mechanic_id: ORDER_TYPES.Ascending };
  switch (sort) {
    case SORT_TYPES.ONLINE_POPULARITY: {
      return {
        popular_order: `${order}_nulls_last`,
        ...orderByMechanicId,
      };
    }
    case SORT_TYPES.EARN_RATE: {
      return [
        { earn_rate: `${order}_nulls_last` },
        { earn_amount: `${order}_nulls_last` },
        orderByMechanicId,
      ];
    }

    case SORT_TYPES.NAME: {
      return {
        mechanic: { merchant: { name: `${order}_nulls_last` } },
      };
    }
    default: {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined;
    }
  }
};

export const getPartnerSlugs = async (
  conditions: PartnerFilterQuery,
  options: PartnerQueryFindOptions = {},
  usePagination = true,
) => {
  const {
    market,
    collectionMechanics = [],
    categories = [],
    externalReferences = [],
    merchantName,
    merchantIds = [],
    isSpecialOffers = false,
  } = conditions;

  // Defaults
  const {
    sort = SORT_TYPES.ONLINE_POPULARITY,
    order = ORDER_TYPES.Ascending,
    currentPage = 0,
    pageSize = 30,
  } = options;

  const queryVariables = {
    externalReferences,
    merchantIds,
    collectionMechanics,
    categories,
    merchantName,
    isSpecialOffers,
  };
  const query = buildPartnerPaginatedQuery(queryVariables);

  const variables = {
    ...(externalReferences.length > 0 ? { externalReferences } : {}),
    ...(merchantIds.length > 0 ? { merchantIds } : {}),
    ...(collectionMechanics.length > 0 ? { collectionMechanics } : {}),
    ...(categories.length > 0 ? { categories } : {}),
    merchantName,
    offset: usePagination ? currentPage * pageSize : DEFAULT_OFFSET,
    limit: usePagination ? pageSize : DEFAULT_LIMIT,
    orderBy: usePagination ? buildOrderByVariable(sort, order) : undefined,
  };

  const partners = await new CBRProvider(
    market,
  ).makeCBRRequest<CBRMechanicCollectionMethodPartnerResponse>({
    query,
    variables,
  });

  return {
    hasError: partners.hasError,
    errors: partners.errors,
    partnerSlugs: [
      ...new Set(
        partners.data?.mechanic_collection_method?.map(
          merchant => merchant.mechanic.merchant.slug,
        ),
      ),
    ],
  };
};

class PartnerLoaderSingleton extends MarketDataLoader<CBRPartner> {
  private static instance: PartnerLoaderSingleton =
    new PartnerLoaderSingleton();

  constructor() {
    if (PartnerLoaderSingleton.instance) {
      throw new Error(
        'Error: Instantiation failed: Use PartnerLoaderSingleton.getInstance() instead of new.',
      );
    }

    super(batcher);

    PartnerLoaderSingleton.instance = this;
  }

  public static getInstance() {
    return PartnerLoaderSingleton.instance;
  }
}

const PartnerLoader = PartnerLoaderSingleton.getInstance();

export default PartnerLoader;
