import { UserProfile } from '@auth0/nextjs-auth0/client';
import { Session } from '@auth0/nextjs-auth0';
import { MEMBERSHIP_ID_KEYS } from '../../constants';

export const getMembershipId = (userProfile: UserProfile): string => {
  const values = MEMBERSHIP_ID_KEYS.map(key =>
    userProfile && userProfile[key]
      ? (userProfile[key] as string).replace('auth0|', '')
      : null,
  );
  return (
    values &&
    (values.find(item => item !== null && item !== undefined) as string)
  );
};

const extractUserData = (session: Session) => ({
  membershipId: getMembershipId(session.user),
  first_name: session.user.given_name,
  last_name: session.user.family_name,
  email: session.user.email,
  title: session.user['https://avios.com/title'],
});

export default extractUserData;
