import { useEffect } from 'react';
import dataDogRum from '@/utils/data-dog-rum';
import { useRouter } from 'next/router';
import trackEvent from '../track-event';
import { createGlobalEventObject } from './use-metrics.utils';
import { UseMetricsParameters } from './use-metrics.types';

export function useMetrics(data: UseMetricsParameters | undefined) {
  const { asPath } = useRouter();

  const {
    programData,
    isLoggedIn,
    membershipId,
    balance,
    daysSinceLastPurchase,
    applicationEnvironment,
  } = data ?? {};

  useEffect(() => {
    if (
      programData?.programmeCode === undefined ||
      programData?.Iso === undefined ||
      isLoggedIn === undefined ||
      membershipId === undefined ||
      balance === undefined ||
      daysSinceLastPurchase === undefined
    )
      return;

    dataDogRum.setGlobalContextProperty('program', {
      id: programData.programmeCode,
      countryCode: programData.Iso,
    });

    dataDogRum.setGlobalContextProperty('isLoggedIn', isLoggedIn);

    if (isLoggedIn) {
      dataDogRum.setUser({
        id: membershipId,
        balance,
        daysSinceLastPurchase,
      });
    }
  }, [
    programData?.programmeCode,
    programData?.Iso,
    isLoggedIn,
    membershipId,
    balance,
    daysSinceLastPurchase,
  ]);

  useEffect(() => {
    if (
      isLoggedIn === undefined ||
      programData === undefined ||
      applicationEnvironment === undefined
    )
      return;

    trackEvent(
      createGlobalEventObject({
        isLoggedIn,
        programData,
        membershipId,
        balance,
        applicationEnvironment,
      }),
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, Boolean(data)]);
}
