import fetcher from '@/utils/fetcher';
import useSWR from 'swr';
import {
  MyAppStaticProperties,
  MyAppProperties,
} from '../../pages/api/properties/properties.types';

export const useAppProperties = () => {
  const { data: staticAppProperties, isLoading: staticIsLoading } =
    useSWR<MyAppStaticProperties>('/api/static-properties/', fetcher);
  const { data: appProperties, isLoading } = useSWR<MyAppProperties>(
    '/api/properties/',
    fetcher,
  );

  return {
    appProperties: {
      ...staticAppProperties,
      ...appProperties,
    },
    isLoading: isLoading || staticIsLoading,
  };
};
