import { ErrorBoundary } from 'next/dist/client/components/error-boundary';
import { MainProperties } from './main.types';
import headerStyles from '../header/header.module.scss';

const ErrorComponent = () => <p>Error on this page</p>;

export const Main = ({
  Component,
  pageProps,
  skeletonHeader = false,
}: MainProperties) => (
  <main>
    <ErrorBoundary errorComponent={ErrorComponent}>
      {skeletonHeader && (
        <div
          data-testid="skeleton-header"
          className={headerStyles['avios-header']}
        />
      )}
      <Component {...pageProps} />
    </ErrorBoundary>
  </main>
);
