import metrics from 'datadog-metrics';
import { ORDER_TYPES, SORT_TYPES } from '../../types';
import { markdownToHTML } from '../../utils/markdown-to-html';
import { IPaginatedResponse } from '../../utils/paginate/paginate.types';
import CollectionMechanicModel from '../collection-mechanic';
import {
  COLLECTION_MECHANIC,
  CollectionMechanic,
} from '../collection-mechanic/collection-mechanic.types';
import PartnerLoader, { getPartnerSlugs } from './partner.loader';
import {
  CBRPartner,
  IMAGE_TYPE,
  PARTNER_METRICS,
  PartnerFilterQuery,
  PartnerQueryFindOptions,
} from './partner.types';
import logger, { formatErrorForLogging } from '../../utils/logger';
import Market from '../market';
import { OPCO_ID } from '../market/market.types';

class Partner {
  public collectionMethods = {} as Record<
    COLLECTION_MECHANIC,
    CollectionMechanic
  >;

  private readonly logger = logger.child({ scope: 'partner model' });

  private loader: Promise<CBRPartner | undefined>;

  private rawData?: CBRPartner;

  constructor(
    private market: Market,
    public slug: string,
  ) {
    this.loader = PartnerLoader.load(slug, market);
  }

  public get isValid() {
    return !!this.rawData;
  }

  private get data() {
    if (!this.rawData) {
      this.logger.warn(`${this.slug} partner did not complete loading`);
      metrics.increment(PARTNER_METRICS.PARTNER_DID_NOT_COMPLETE_LOADING, 1, [
        `slug:${this.slug}`,
      ]);
      this.publishErrorMetric();
      return null;
    }
    return this.rawData;
  }

  public async isReady() {
    try {
      const loaderResponse = await this.loader;
      if (loaderResponse?.mechanics.length === 0 || !loaderResponse?.slug)
        throw new Error(`No Partner Found for slug: ${this.slug}`);

      const { EStore, CLO, Manual, Convert, Purchase } = COLLECTION_MECHANIC;

      const collectionMechanics =
        loaderResponse.mechanics[0].mechanic_collection_methods
          .filter(currentMechanic =>
            [EStore, CLO, Manual, Convert, Purchase].includes(
              currentMechanic.collection_method.name,
            ),
          )
          .map(
            currentMechanic =>
              new CollectionMechanicModel(
                this.market,
                this.slug,
                currentMechanic.collection_method.name,
              ),
          );

      await Promise.all(
        collectionMechanics.map(collectionMechanic =>
          collectionMechanic.isReady(),
        ),
      );

      for (const currentMechanic of collectionMechanics) {
        this.collectionMethods[currentMechanic.key] = currentMechanic;
      }

      this.rawData = loaderResponse;
    } catch (error) {
      this.publishErrorMetric();
      this.logger.error(`partner isReady`, {
        error: formatErrorForLogging(error),
      });
    }
  }

  public get categorySlugs() {
    if (this.data) {
      return this.data.merchant_categories.map(({ category }) => category.slug);
    }
    return [];
  }

  public get primaryCategorySlug() {
    if (this.data) {
      return this.data.merchant_primary_categories[0].primary_category.slug;
    }
    return '';
  }

  public get category() {
    const firstCategory = this.data?.merchant_categories[0] ?? null;
    if (firstCategory) {
      const { category } = firstCategory;

      const name =
        category.category_locales.find(({ locale }) => {
          return locale.name === this.market.locale;
        })?.name ?? category.name;

      return {
        slug: category.slug,
        name,
        url: `retailers?c=${category.slug}`,
      };
    }
    return null;
  }

  public get id() {
    return this.data?.merchant_id ?? null;
  }

  public get name() {
    return this.data?.name ?? '';
  }

  public get externalReference() {
    return this.data?.external_reference ?? '';
  }

  public get description() {
    const description = this.data?.description ?? '';
    return markdownToHTML(description);
  }

  public get logoSrc() {
    return (
      this.data?.merchant_images.find(
        img => img.image_type.name === IMAGE_TYPE.Logo,
      )?.image_url ?? ''
    );
  }

  public get heroSrc() {
    return (
      this.data?.merchant_images.find(
        img => img.image_type.name === IMAGE_TYPE.Image,
      )?.image_url ?? ''
    );
  }

  public get destinationUrl() {
    const keys = Object.keys(this.collectionMethods);

    if (
      keys.length === 1 &&
      (keys[0] === COLLECTION_MECHANIC.Purchase ||
        keys[0] === COLLECTION_MECHANIC.Convert)
    ) {
      return this.collectionMethods[keys[0]].url;
    }

    return `/retailers/${this.slug}`;
  }

  private getRelatedPartnerSlugsUnchecked() {
    return (
      this.data?.merchant_categories[0].category.merchant_categories
        .filter(({ merchant }) => merchant.slug !== this.slug)
        .map(({ merchant }) => merchant.slug) ?? []
    );
  }

  public get relatedPartnerSlugs() {
    try {
      return this.getRelatedPartnerSlugsUnchecked();
    } catch (error) {
      this.publishErrorMetric();
      logger.error('Error while getting partner slugs', {
        error: formatErrorForLogging(error),
      });
      throw error;
    }
  }

  static publishErrorMetric(
    opCoId: OPCO_ID,
    partnerSlug = 'unkown',
    requestPath = 'unkown',
    locale = 'unkown',
  ) {
    metrics.increment(PARTNER_METRICS.PARTNER_GENERIC_ERROR, 1, [
      `slug:${partnerSlug}`,
      `path:${requestPath}`,
      `opco:${opCoId}`,
      `locale:${locale}`,
    ]);
  }

  private publishErrorMetric() {
    Partner.publishErrorMetric(
      this.market.opCoId,
      this.slug,
      this.market.requestPath,
      this.market.locale,
    );
  }

  public async getRelatedPartners() {
    try {
      const partners = this.getRelatedPartnerSlugsUnchecked().map(
        slug => new Partner(this.market, slug),
      );

      await Promise.all(partners.map(partner => partner.isReady()));

      return partners;
    } catch (error) {
      this.publishErrorMetric();
      this.logger.error(`Error in getRelatedPartners():`, {
        error: formatErrorForLogging(error),
      });
      return [];
    }
  }

  public get mechanicId() {
    if (this.data) {
      return this.data.mechanics[0].mechanic_id;
    }
    return null;
  }

  public get ctaTextOverwrite() {
    if (this.data) {
      return this.data.mechanics[0].mechanic_collection_methods[0]
        .collection_method_locale_overrides?.[0]?.cta;
    }
    return null;
  }

  public getHighestRateMechanic() {
    const [highestRateMechanic] = Object.values(this.collectionMethods).sort(
      (a, b) => (b.earnValue ?? 0) - (a.earnValue ?? 0),
    );
    return highestRateMechanic;
  }

  static async find(
    conditions: PartnerFilterQuery,
    options: PartnerQueryFindOptions = {},
  ): Promise<IPaginatedResponse<Partner[]>> {
    try {
      const [response, responseWithoutPagination] = await Promise.all([
        getPartnerSlugs(conditions, options),
        getPartnerSlugs(conditions, options, false),
      ]);

      // Pagination Defaults
      const { currentPage = 0, pageSize = 30 } = options;

      const { market } = conditions;
      const slugs = response.partnerSlugs.slice(0, pageSize);
      const pagination = {
        entries: currentPage * pageSize + slugs.length,
        total: responseWithoutPagination.partnerSlugs.length,
        page: currentPage,
      };

      const partners = slugs.map(slug => new Partner(market, slug));

      await Promise.all(partners.map(partner => partner.isReady()));

      return {
        data: partners,
        pagination,
      };
    } catch (error) {
      Partner.publishErrorMetric(
        conditions.market.opCoId,
        conditions.merchantName,
        conditions.market.requestPath,
        conditions.market.locale,
      );
      logger.error('An error occurred while finding partners', {
        error: formatErrorForLogging(error),
      });

      return {
        data: [],
        pagination: {
          entries: 0,
          total: 0,
          page: 0,
        },
      };
    }
  }

  static async getPopularPartners(
    conditions: Omit<PartnerFilterQuery, 'categories'>,
    limit = 16,
  ): Promise<IPaginatedResponse<Partner[]>> {
    const options = {
      sort: SORT_TYPES.ONLINE_POPULARITY,
      order: ORDER_TYPES.Ascending,
      currentPage: 0,
      pageSize: limit,
    };
    return Partner.find({ ...conditions }, options);
  }

  static async findByConditions(conditions: PartnerFilterQuery) {
    const response = await getPartnerSlugs(conditions);

    return response.partnerSlugs.map(slug => {
      return new Partner(conditions.market, slug);
    });
  }

  public get externalReferences() {
    return Object.fromEntries(
      Object.entries(this.collectionMethods).map(
        ([key, { externalReference }]) => [key, externalReference],
      ),
    );
  }
}

export default Partner;
