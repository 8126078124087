import { GetSession } from '@auth0/nextjs-auth0';
import { GetSessionParameters, SessionMaybe } from './auth0.types';
import { getAuth0Server } from './auth0.server-provider';
import logger from '../../utils/logger';
import { refreshSession } from './auth0.utils';
import Market from '../../models/market';

export const getSession = ({
  market,
  requestResponse,
}: GetSessionParameters): Promise<SessionMaybe> =>
  getAuth0Server(market).getSession(...requestResponse);

export const refreshOutdatedTokens = async (
  market: Market,
  requestResponse: Exclude<Parameters<GetSession>, []>,
) => {
  const session = await getSession({ market, requestResponse });
  if (
    session?.accessTokenExpiresAt &&
    session.refreshToken &&
    (session.accessTokenExpiresAt - 60) * 1000 < Date.now()
  ) {
    logger.info('Outdated session detected. Refreshing.');

    await refreshSession({
      session,
      market,
      requestResponse,
    });
  }

  return getSession({ market, requestResponse });
};
