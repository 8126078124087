export type NextImageProperties = {
  src: string;
  fill?: boolean;
  alt: string;
  className?: string;
  ariaHidden?: boolean;
  overideHeight?: string;
  crossOrigin?: '' | 'anonymous' | 'use-credentials';
  height?: number;
  width?: number;
  isLocal?: boolean;
  isStorybook?: boolean;
  priority?: boolean;
  quality?: number;
  fallbackSource?: string;
};

export enum IMAGE_DESCRIPTION_METRICS {
  CONTENTFUL_MISSING_DESCRIPTION = 'contentful.image.missing_description',
  COLLINSON_MISSING_DESCRIPTION = 'collinson.image.missing_description',
}
