import { COLLECTION_MECHANIC } from '@/models/collection-mechanic/collection-mechanic.types';
import Partner from '@/models/partner';
import RecommendedRetailersProvider from '@/providers/recommended-retailers';
import {
  BannersCarouselCollinsonData,
  BannersCarouselCollinsonResponse,
  CONTENT_TYPES,
  DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE,
  GetCollinsonBannersParameters,
  GetTopPicksParameters,
  HandleDynamicVersatileCardParameters,
  IMAGE_POSITION,
  VersatileCardContentfulResponse,
} from '@/utils/contentful-data-formatters/contentful-data-formatters.types';
import { getMembershipId } from '@/utils/extract-user-data';
import { VARIANT as PARTNER_TILE_VARIANT } from '@/components/integrated/partner-tile/partner-tile.types';
import {
  TEXT_ALIGN,
  TEXT_COLOR,
} from '@/components/integrated/versatile-card/versatile-card.types';
import CollinsonProvider from '@/providers/collinson';
import { increment } from 'datadog-metrics';
import { IMAGE_DESCRIPTION_METRICS } from '@/components/data-display/image/image.types';
import logger from '@/utils/logger';

export const DEFAULT_VCC_IMAGE_HEIGHT = 300;
export const DEFAULT_VCC_TEXT_ALIGN = TEXT_ALIGN.Left;
export const DEFAULT_VCC_TEXT_COLOR = TEXT_COLOR.Primary;

export const getTopPicks = async ({
  session,
  b2bToken,
  market,
  maxPartners,
}: GetTopPicksParameters) => {
  if (!session?.user) return [];

  const provider = new RecommendedRetailersProvider(market, b2bToken);
  let retailersData = await provider.getRecommendedRetailers(
    getMembershipId(session.user),
  );

  if (!retailersData)
    retailersData = await provider.getRecommendedRetailers(
      getMembershipId(session.user),
    );

  let partners: Partner[] | undefined;
  if (retailersData && retailersData.length > 0) {
    partners = await Partner.findByConditions({
      market,
      merchantIds: retailersData.map(Number),
    });

    await Promise.all(partners.map(partner => partner.isReady()));
  }

  // if no top picks found, use popular eStore partners
  if (!partners || partners.filter(x => x.isValid).length === 0) {
    const relatedPartnersPromise = await Partner.getPopularPartners({
      collectionMechanics: [COLLECTION_MECHANIC.EStore],
      market,
    });
    await Promise.all(
      relatedPartnersPromise.data.map(partner => partner.isReady()),
    );
    partners = relatedPartnersPromise.data;
  }
  return partners
    .filter(x => x.isValid)
    .slice(0, maxPartners ?? 12)
    .map<VersatileCardContentfulResponse>(partner => {
      const {
        collectionMethods,
        id,
        name,
        slug,
        logoSrc,
        heroSrc,
        mechanicId,
      } = partner;

      let mechanicRate = null;
      let mechanicHasSpeedy = null;
      let mechanicWasRate = null;
      for (const mechanic of Object.values(collectionMethods)) {
        const { rate, isSpeedyAwarding, wasRate } = mechanic;
        mechanicRate = rate;
        mechanicHasSpeedy = isSpeedyAwarding;
        mechanicWasRate = wasRate;
      }
      const baseVersatileCard = createBaseVersatileCard();
      return {
        ...baseVersatileCard,
        sys: {
          id: String(id),
        },
        contentType: 'VersatileCard',
        name,
        partner: [
          {
            partnerId: id ?? undefined,
            name,
            slug,
            logoSrc,
            // the way we handle partners with `isReady` causes this
            // we are sure mechanic ID is defined because we've awaited for `isReady`
            // but TS doesn't know about this because it's runtime logic
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            mechanicId: mechanicId!,
            isSpeedyAwarding: mechanicHasSpeedy ?? undefined,
            wasRate: mechanicWasRate,
            rate: mechanicRate,
            variant: PARTNER_TILE_VARIANT.Default,
            override: {
              heroSrc: '',
            },
          },
        ],
        partnerVariant: PARTNER_TILE_VARIANT.Default,
        image: {
          url: heroSrc,
          title: name,
          description: null,
        },
        imagePosition: IMAGE_POSITION.Top,
        imageHeight: 125,
        imageMobileHeight: DEFAULT_VCC_IMAGE_HEIGHT,
        textColor: DEFAULT_VCC_TEXT_COLOR,
        textAlign: DEFAULT_VCC_TEXT_ALIGN,
        cardIsPrimaryLink: true,
        linkPrimary: `/retailers/${slug}`,
        linkLabelPrimary: name,
        showPartnerFavouriteToggle: true,
        borderless: false,
      };
    });
};

export const getCollinsonBanners = async ({
  market,
  maxPartners,
}: GetCollinsonBannersParameters) => {
  const collinson = new CollinsonProvider(market);
  const bannersData =
    await collinson.getBanners<BannersCarouselCollinsonResponse>();

  const externalReferences = bannersData.hasError
    ? null
    : bannersData.data?.map(
        (banner: BannersCarouselCollinsonData) => banner.object_id,
      );

  if (!externalReferences || externalReferences.length === 0)
    return { banners: null, bannerHasError: bannersData.hasError ?? null };

  const partners = bannersData.hasError
    ? []
    : await Partner.findByConditions({
        market,
        collectionMechanics: [COLLECTION_MECHANIC.EStore],
        externalReferences,
      });

  await Promise.all(partners.map(partner => partner.isReady()));
  if (bannersData.hasError) return null;

  return partners
    .filter(x => x.isValid && x.id !== null)
    .slice(0, maxPartners ?? 12)
    .sort(
      (a, b) =>
        externalReferences.indexOf(a.externalReference) -
        externalReferences.indexOf(b.externalReference),
    )
    .map(partner => {
      const {
        collectionMethods,
        id,
        name,
        slug,
        logoSrc,
        externalReference,
        destinationUrl = '',
      } = partner;

      let mechanicWasRate = null;
      let mechanicRate = null;
      let mechanicCtaText = null;
      for (const mechanic of Object.values(collectionMethods)) {
        const { rate, wasRate, ctaText } = mechanic;
        mechanicRate = rate;
        mechanicWasRate = wasRate;
        mechanicCtaText = ctaText;
      }

      const banner = bannersData.data.find(
        bannerElement => bannerElement.object_id === externalReference,
      );

      const bannerAlt = banner?.alt ?? '';
      if (!bannerAlt) {
        increment(IMAGE_DESCRIPTION_METRICS.COLLINSON_MISSING_DESCRIPTION, 1, [
          `image: ${banner?.image}`,
        ]);
        logger.info('Banner image missing alt property', {
          url: banner?.url,
          image: banner?.image,
        });
      }
      const baseVersatileCard = createBaseVersatileCard();
      return {
        ...baseVersatileCard,
        sys: {
          id: String(id),
        },
        contentType: 'VersatileCard',
        name,
        partner: [
          {
            partnerId: id ?? undefined,
            name,
            slug,
            logoSrc,
            wasRate: mechanicWasRate,
            rate: mechanicRate,
            variant: PARTNER_TILE_VARIANT.Default,
            override: {
              heroSrc: '',
            },
          },
        ],
        partnerVariant: PARTNER_TILE_VARIANT.Default,
        image: {
          url: banner?.image,
          title: bannerAlt,
        },
        imagePosition: IMAGE_POSITION.Right,
        imageHeight: 125,
        imageMobileHeight: DEFAULT_VCC_IMAGE_HEIGHT,
        textColor: DEFAULT_VCC_TEXT_COLOR,
        textAlign: DEFAULT_VCC_TEXT_ALIGN,
        cardIsPrimaryLink: true,
        borderless: false,
        imageWidth: '70',
        buttonsCollection: {
          items: [
            {
              sys: {
                id: destinationUrl,
              },
              label: mechanicCtaText,
              url: destinationUrl,
              variant: 'Primary',
              size: 'Small',
              image: null,
            },
          ],
        },
        ctaFullWidth: true,
      };
    });
};

export const createBaseVersatileCard = () => {
  return {
    sys: {
      id: null,
    },
    contentType: CONTENT_TYPES.VersatileCard,
    name: null,
    partner: [
      {
        partnerId: null,
        name: null,
        slug: null,
        logoSrc: null,
        mechanicId: null,
        isSpeedyAwarding: null,
        wasRate: null,
        rate: null,
        variant: null,
        override: {
          heroSrc: null,
        },
      },
    ],
    partnerVariant: null,
    image: {
      url: null,
      title: null,
      description: null,
    },
    imagePosition: null,
    imageHeight: null,
    imageMobileHeight: null,
    textColor: null,
    textAlign: null,
    cardIsPrimaryLink: null,
    linkPrimary: null,
    linkLabelPrimary: null,
    showPartnerFavouriteToggle: null,
    borderless: null,
    buttonsCollection: { items: [] },
    htmlId: null,
    backgroundColor: null,
    imageAlt: null,
    imageWidth: null,
    imageContained: null,
    imageOptimised: null,
    headingOne: null,
    headingTwo: null,
    headingThree: null,
    headingFour: null,
    headingFive: null,
    bodyContent: null,
    spaceBetweenHeadingAndBody: false,
    linkSecondary: null,
    linkLabelSecondary: null,
    ctaLabelPrimary: null,
    ctaFullWidth: null,
    imageButtonPrimary: null,
    imageButtonPrimaryUrl: null,
    imageButtonSecondary: null,
    imageButtonSecondaryUrl: null,
    youtubeId: null,
    youtubeTitle: null,
    youtubeAutoPlay: null,
    youtubeVideoHeight: null,
    partnerTextSize: null,
    partnerLogoSize: null,
    partnerSeparatorLine: null,
    openCtasInNewTab: null,
    openLinksInNewTab: null,
  };
};

export const handleDynamicVersatileCard = ({
  session,
  b2bToken,
  market,
  dynamicVersatileCard,
}: HandleDynamicVersatileCardParameters) => {
  if (
    dynamicVersatileCard.externalSource ===
    DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE.TopPicks
  ) {
    return getTopPicks({
      session,
      b2bToken,
      market,
      maxPartners: dynamicVersatileCard.maximumNumberOfCards,
    });
  }

  if (
    dynamicVersatileCard.externalSource ===
    DYNAMIC_VERSATILE_CARD_EXTERNAL_SOURCE.CollinsonBanners
  ) {
    return getCollinsonBanners({
      b2bToken,
      market,
      maxPartners: dynamicVersatileCard.maximumNumberOfCards,
    });
  }

  return [];
};
