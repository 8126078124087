import { createServerSideContext } from './server-side-context';
import { ServerSideContext } from './server-side-context.types';

export const globalServerSideContext = (): ServerSideContext => {
  if (!(globalThis as any).globalServerSideContextInstance) {
    (globalThis as any).globalServerSideContextInstance =
      createServerSideContext();
  }

  return (globalThis as any).globalServerSideContextInstance;
};
