import { VersatileCardProperties } from '@/components/integrated/versatile-card/versatile-card.types';
import { VersatileCardResponse } from '../../utils/contentful-data-formatters/contentful-data-formatters.types';

export type PromotionalModalResponse = {
  promotionalModalCollection: {
    items: Array<{
      modalContent: VersatileCardResponse;
      cookieValue: string;
      showForAllDevices: boolean;
      showForAndroid: boolean;
      showForIphone: boolean;
      showDelay: number;
    }>;
  };
  microcopyCollection: {
    items: Array<{
      key: string;
      value: string;
    }>;
  };
};

export type PromotionalModalProperties = {
  id: string;
  cookie: string;
  versatileCardContent: VersatileCardProperties;
  showDelay: number;
  showForAllDevices: boolean;
  showForAndroid: boolean;
  showForIphone: boolean;
  closeLabel: string;
  isAvios: boolean;
};

export enum PROMOTIONAL_MODAL_CLOSE_EVENT {
  BUTTON = 'BUTTON',
  OUTSIDE = 'OUTSIDE',
  MAIN_ACTION = 'MAIN_ACTION',
}
