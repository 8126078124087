import { Handler } from '@auth0/nextjs-auth0/src/handlers/router-helpers';
import { NextApiRequest, NextApiResponse } from 'next';
import { COOKIES } from '@/types';
import { AVIOS_HOME, HEADERS } from '@/constants';
import { isAviosOpco } from '@/utils/opco-utils';
import { Response } from 'express';
import logger from '@/utils/logger';
import { Market } from '../../models/market/market.types';
import { mainAudienceData } from './auth0.constants';
import {
  getSanitizedReturnTo,
  isSilentLogin,
  getFormattedSilentLoginCookieFlag,
  getFormattedReturnToCookie,
  getFormattedClearedAppSessionCookies,
  processAuth0Handler,
} from './auth0.utils';
import { getAuth0Server } from './auth0.server-provider';
import { getAuth0BaseConfig } from './auth0.config';

const handleCallback = (
  market: Market,
  auth0Server: ReturnType<typeof getAuth0Server>,
) =>
  (async (request: NextApiRequest, response: NextApiResponse) => {
    const userNotLoggedInError = request.query.error === 'login_required';
    const isSilentLoginFlag = isSilentLogin(request);
    const { opCoId } = market;
    const { baseURL } = getAuth0BaseConfig(market);

    logger.info('handling callback', {
      queryError: request.query.error ?? 'no error',
      userNotLoggedInError,
      isSilentLoginFlag,
      opCoId,
      baseURL,
    });

    const aviosHome = isAviosOpco(opCoId) ? AVIOS_HOME : '';
    if (isSilentLoginFlag && userNotLoggedInError) {
      const returnTo =
        getSanitizedReturnTo(
          request.cookies[COOKIES.SILENT_LOGIN_RETURN_TO_BASE64],
          baseURL,
        ) ?? `${aviosHome}/`;

      return response
        .setHeader(HEADERS.SET_COOKIE, [
          getFormattedReturnToCookie(btoa(returnTo), false),
          ...getFormattedClearedAppSessionCookies(request),
        ])
        .redirect(returnTo);
    }

    return processAuth0Handler({
      response,
      opCoId,
      handlerPromise: auth0Server.handleCallback(
        request,
        response,
      ) as Promise<Response>,
    });
  }) as Handler;

const handleLogout = (
  market: Market,
  auth0Server: ReturnType<typeof getAuth0Server>,
) =>
  ((request: NextApiRequest, response: NextApiResponse) => {
    const { opCoId } = market;
    const { baseURL } = getAuth0BaseConfig(market);

    const aviosHome = isAviosOpco(opCoId) ? AVIOS_HOME : '';
    const defaultUrl = `${aviosHome}/`;

    const queryReturnTo =
      (typeof request.query.logoutReturnTo === 'string' &&
        getSanitizedReturnTo(btoa(request.query.logoutReturnTo), baseURL)) ||
      '';

    const returnTo = queryReturnTo.includes('my-account')
      ? defaultUrl
      : queryReturnTo;

    response.setHeader(
      HEADERS.SET_COOKIE,
      queryReturnTo
        ? [
            ...getFormattedClearedAppSessionCookies(request),
            `${COOKIES.LOGOUT_RETURN_TO}=${btoa(returnTo)};Path=/`,
          ]
        : getFormattedClearedAppSessionCookies(request),
    );

    return processAuth0Handler({
      response,
      opCoId,
      handlerPromise: auth0Server.handleLogout(
        request,
        response,
      ) as Promise<Response>,
    });
  }) as Handler;

const silentLoginHandler =
  (market: Market, auth0Server: ReturnType<typeof getAuth0Server>) =>
  (request: NextApiRequest, response: NextApiResponse) => {
    const { opCoId } = market;
    const { baseURL } = getAuth0BaseConfig(market);

    const aviosHome = isAviosOpco(opCoId) ? AVIOS_HOME : '';
    const validReturnTo =
      (typeof request.query.silentLoginReturnTo === 'string' &&
        getSanitizedReturnTo(request.query.silentLoginReturnTo, baseURL)) ||
      `${aviosHome}/`;

    response.setHeader(HEADERS.SET_COOKIE, [
      getFormattedSilentLoginCookieFlag(true),
      getFormattedReturnToCookie(btoa(validReturnTo), true),
    ]);

    return processAuth0Handler({
      response,
      opCoId,
      returnToOnFailure: validReturnTo,
      handlerPromise: auth0Server.handleLogin({
        authorizationParams: {
          audience: mainAudienceData.audience,
          scope: mainAudienceData.scope,
          prompt: 'none',
        },
        returnTo: validReturnTo,
      })(request, response) as Promise<Response>,
    });
  };

export const getAuth0Handler = (market: Market) => {
  const auth0Server = getAuth0Server(market);

  return auth0Server.handleAuth({
    logout: handleLogout(market, auth0Server),
    callback: handleCallback(market, auth0Server),
    'silent-login': silentLoginHandler(market, auth0Server),
  });
};
