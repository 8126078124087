import fetcher from '@/utils/fetcher';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import { FC } from 'react';
import ClientSideMarketContext from './client-side-market';
import FeatureTogglesContext from './feature-toggles';
import SharedComponentsProvider from './shared-components/shared-components-provider';
import { ShoppingProviderComponentProperties } from './index.types';

export const ShoppingProviders: FC<ShoppingProviderComponentProperties> = ({
  children,
  clientSideMarket,
  featureToggles,
  loadingComponent,
  aviosCMSToken,
  sharedHeaderFooterEnvironment,
}) => (
  <ClientSideMarketContext.Provider value={clientSideMarket}>
    <FeatureTogglesContext.Provider value={featureToggles}>
      <UserProvider fetcher={fetcher}>
        <SharedComponentsProvider
          cms={{
            aviosCMSToken,
            sharedHeaderFooterEnvironment,
          }}
          opCoId={clientSideMarket.opCoId}
          loadingComponent={loadingComponent}
        >
          {children}
        </SharedComponentsProvider>
      </UserProvider>
    </FeatureTogglesContext.Provider>
  </ClientSideMarketContext.Provider>
);
