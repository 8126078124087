import { markdownToHTML } from '../../utils/markdown-to-html';
import {
  CBRCollectionMechanic,
  COLLECTION_MECHANIC,
  ContentfulTAndC,
} from './collection-mechanic.types';
import { Market } from '../market/market.types';
import CollectionMechanicCbrLoader from './collection-mechanic.cbr.loader';
import CollectionMechanicContentfulLoader from './collection-mechanic.contentful.loader';
import { buildUrlPath } from '../../utils/build-url-path';

class CollectionMechanic {
  private cbrLoader: Promise<CBRCollectionMechanic | undefined>;

  private contentfulLoader: Promise<ContentfulTAndC | undefined>;

  private rawContent?: ContentfulTAndC;

  private rawData?: CBRCollectionMechanic | false;

  public collectionMethods: Partial<
    Record<COLLECTION_MECHANIC, CollectionMechanic>
  > = {};

  constructor(
    private market: Market,
    private slug: string,
    private collectionMechanic: COLLECTION_MECHANIC,
  ) {
    this.contentfulLoader = CollectionMechanicContentfulLoader.load(
      collectionMechanic,
      market,
    );

    this.cbrLoader = CollectionMechanicCbrLoader.load(
      { slug, collectionMechanic },
      market,
    );
  }

  private get content() {
    if (!this.rawContent) {
      throw new Error('Collection mechanic content has not completed loading');
    }

    return this.rawContent;
  }

  private get data() {
    if (this.rawData === undefined) {
      throw new Error('Collection mechanic has not completed loading');
    }

    if (this.rawData === false) {
      throw new Error(
        `${this.collectionMechanic} collection mechanic does not exist for ${this.slug}`,
      );
    }

    return this.rawData;
  }

  public async isReady() {
    const [cbrResponse, contentfulResponse] = await Promise.all([
      this.cbrLoader,
      this.contentfulLoader,
    ]);

    this.rawData = cbrResponse ?? false;
    this.rawContent = contentfulResponse ?? {};
  }

  public get tiers() {
    return this.data.mechanic_collection_method_tiers.map(tier => ({
      id: tier.tier_id,
      name: tier.name,
      rate: tier.rate,
    }));
  }

  public get termsAndConditions() {
    return markdownToHTML(this.content.termsAndConditions);
  }

  public get termsAndConditionsTitle() {
    return markdownToHTML(this.content.termsAndConditionsTitle);
  }

  public get purchaseConditions() {
    return markdownToHTML(this.data.purchase_conditions);
  }

  public get additionalPurchaseConditions() {
    return markdownToHTML(this.data.additional_purchase_conditions);
  }

  public get key() {
    return this.data.collection_method.name;
  }

  public get earnValue() {
    return this.data.earn_amount ?? this.data.earn_rate;
  }

  public get rate() {
    return this.activeOffer?.rate ?? this.data.rate;
  }

  public get popularOrder() {
    return this.data.popular_order;
  }

  public get wasRate() {
    return this.activeOffer?.was_rate ?? null;
  }

  public get isSpeedyAwarding() {
    return this.data.is_speedy_awarding;
  }

  public get ctaText() {
    const locales = this.data.collection_method.collection_method_locales;

    const marketLocaleValue = locales.find(({ locale }) => {
      return locale.name === this.market.locale;
    });

    const fallbackLocaleValue = locales.find(({ locale }) => {
      return locale.name === 'en-GB';
    });

    const ctaValue = marketLocaleValue
      ? marketLocaleValue?.cta
      : fallbackLocaleValue?.cta;

    return ctaValue ?? '';
  }

  public get isSpecialOffer() {
    return this.data.offers.length > 0;
  }

  public get externalReference() {
    return this.data.external_reference;
  }

  public get url() {
    return this.data.url ?? '';
  }

  public get ctaLink() {
    if (
      this.key === COLLECTION_MECHANIC.Manual ||
      this.key === COLLECTION_MECHANIC.Convert ||
      this.key === COLLECTION_MECHANIC.Purchase
    ) {
      return {
        url: buildUrlPath(this.data.url ?? ''),
        target: '_blank',
      };
    }

    if (this.key === COLLECTION_MECHANIC.CLO) {
      return {
        url: `/my-account/registered-cards`,
        target: '_self',
      };
    }

    return {
      url: `/retailers-redirection/${this.externalReference}/${this.slug}/shop-now/web`,
      target: '_blank',
    };
  }

  private get activeOffer() {
    return this.data.offers.find(({ start_date, end_date }) => {
      const startDate = new Date(start_date).getTime();
      const endDate = new Date(end_date).getTime();

      return Date.now() > startDate && Date.now() < endDate;
    });
  }

  public get trackedTimelineUnit() {
    return this.data.tracked_timeline_unit;
  }

  public get trackedTimelinePeriod() {
    return this.data.tracked_timeline_period;
  }

  public get awardedTimelineUnit() {
    return this.data.awarded_timeline_unit;
  }

  public get awardedTimelinePeriod() {
    return this.data.awarded_timeline_period;
  }
}

export default CollectionMechanic;
