import { CLICK_LOCATION } from '../../../utils/register-partner-click/register-partner-click.types';
import {
  PartnerTileSimple,
  VersatileCardFormatted,
} from '../../../utils/contentful-data-formatters/contentful-data-formatters.types';

export type PartnerListProperties = {
  partnerList?: PartnerListType;
  speedyAwardText?: string;
  className?: string;
  listType?: LIST_TYPE;
  tileType?: TILE_TYPE;
  clickLocation?: CLICK_LOCATION;
  isCLOList?: boolean;
};

export type PartnerListType = Array<VersatileCardFormatted & PartnerTileSimple>;

export enum TILE_TYPE {
  Simple = 'simple',
  Hero = 'hero',
}

export enum LIST_TYPE {
  col6 = 'partner-list--col6',
  col5 = 'partner-list--col5',
  col4 = 'partner-list--col4',
  col3 = 'partner-list--col3',
  col2 = 'partner-list--col2',
  col2Strict = 'partner-list--col2-strict', // strict denotes number of cols remain the same regardless of breakpoint
  carousel = 'partner-list--carousel',
}
