import type { FooterProps } from '@channel/shared-components';
import { AnchorHTMLAttributes, LegacyRef, ReactElement } from 'react';

export type LocaleSelectorLabelProperties = {
  icon: string;
  language: string;
};

export type FooterProperties = {
  navigation: Array<{ destination: string; children: string }>;
  aviosText: string;
  copyright: string;
  aviosLink: string;
  aviosLogo: {
    src: string;
    alt: string;
  };
  availableLocales: string[];
  currentLocale: string;
  footerLabelsCollection: {
    [key: string]: string;
  };
  sharedFooterProps: Omit<FooterProps, 'currentPath'> & {
    channelsClubUrl: string;
  };
};

export type FooterQueryResponse = {
  [FOOTER_COLLECTION.WithProgramAndCountry]: {
    items: FooterCollectionItem[];
  };
  [FOOTER_COLLECTION.WithProgram]: {
    items: FooterCollectionItem[];
  };
  [FOOTER_COLLECTION.Default]: {
    items: FooterCollectionItem[];
  };
  microcopyCollection: {
    items: Array<{
      key: string;
      value: string;
    }>;
  };
};

export type FooterCollectionItem = {
  aviosLogo: { title: string; url: string };
  copyright: string;
  aviosText: string;
  aviosLink: string;
  navigationCollection: {
    items: Array<{
      label: string;
      urlPath: string;
      isHighlighted: boolean;
    }>;
  };
};

export enum FOOTER_COLLECTION {
  WithProgramAndCountry = 'withProgramAndCountry',
  WithProgram = 'withProgram',
  Default = 'default',
}

export enum FOOTER_LABEL {
  EN_GB = 'footer.label.en_gb',
  EN_US = 'footer.label.en_us',
  EN_IE = 'footer.label.en_ie',
  ES_ES = 'footer.label.es_es',
}

export enum LOCALE {
  EN_GB = 'en-GB',
  EN_US = 'en-US',
  EN_IE = 'en-IE',
  ES_ES = 'es-ES',
}

export type SharedFooterLinkProperties = {
  children: ReactElement;
  reference: LegacyRef<HTMLAnchorElement>;
} & AnchorHTMLAttributes<HTMLAnchorElement>;
