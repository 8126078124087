import { OPCO_ID_EXTERNAL_SERVICE } from '@/constants';
import { Market } from '../../models/market/market.types';
import Fetch from '../../utils/fetch';
import logger from '../../utils/logger';
import { globalServerSideContext } from '../../utils/server-side-context';
import { getFSConfig } from './financial-services.config';
import {
  Config,
  GetFinancialServicesResponse,
  PLACEMENT_ID,
} from './financial-services.types';
import { formatAmexMGM } from './financial-services.utils';

export default class FSProvider extends Fetch {
  private config: Config;

  constructor(private market: Market) {
    const selectedConfig = getFSConfig();

    super(selectedConfig.BASE_URL);

    this.config = selectedConfig;
  }

  private setHeaders(accessToken: string) {
    this.headers = {
      api_key: this.config.API_KEY,
      Authorization: accessToken,
    };
  }

  @(globalServerSideContext().cacheMethod())
  async getFinancialServices(membershipId: string) {
    const accessToken = globalServerSideContext().getIdentityToken();
    if (!accessToken) return null;

    this.setHeaders(accessToken);
    const response = this.get<GetFinancialServicesResponse>(
      `/fs/nba/${
        OPCO_ID_EXTERNAL_SERVICE[this.market.opCoId]
      }/${membershipId}?locale=en_GB&placementId=${
        PLACEMENT_ID.BRITISH_AIRWAYS
      }`,
      { headers: { Authorization: accessToken } },
    );

    const data = await response;

    const responseKeys = Object.keys(data);
    const amexProduct = responseKeys.some(key => key.includes('FS1'));

    if (!data.widget?.external_rafaas_responses) {
      if (amexProduct) logger.error(`Financial Service - widget`, data);
      return data;
    }

    const { response_status_code, http_status, response_status_message_text } =
      data.widget.external_rafaas_responses[0];
    const isAmexMGMAvailable =
      response_status_code === '200' && http_status === 'OK';

    if (!isAmexMGMAvailable) {
      logger.error(
        `Financial Service - Amex MGM ${response_status_message_text}`,
      );
      return data;
    }

    return {
      ...data,
      widget: amexProduct ? formatAmexMGM(data.widget) : null,
    };
  }
}
