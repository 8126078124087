import {
  AccessTokenError,
  AccessTokenErrorCode,
  AccessTokenRequest,
  CallbackHandlerError,
  Session,
} from '@auth0/nextjs-auth0';
import { StatusCodes } from 'http-status-codes';
import { NextApiRequest, NextApiRequestInjected } from 'next';
import { isAviosOpco } from '../../utils/opco-utils';
import { COOKIES } from '../../types';
import {
  FeatureTogglesType,
  FEATURE_TOGGLE_LIST,
} from '../../context/feature-toggles/feature-toggles.types';
import { AVIOS_PREFIX } from '../../constants';
import { GENERIC_ERROR_CODES } from '../../modules/generic-error-section/generic-error-section.types';
import { Market } from '../../models/market/market.types';
import logger, { formatErrorForLogging } from '../../utils/logger';
import { getCollinsonUser } from '../collinson/collinson.utils';
import {
  mainAudienceData,
  SILENT_LOGIN_FLAG_EXPIRY_TIME_IN_SECONDS,
  userAgentsSubstringToSkipSilentLogin,
} from './auth0.constants';
import { getAuth0Server } from './auth0.server-provider';
import {
  HandleRefreshErrorParameters,
  ProcessAuth0HandlerParameters,
  RefreshAudienceTokenParameters,
  SessionMaybe,
} from './auth0.types';

export class DecodeJwtError extends Error {}

export const getSanitizedReturnTo = (
  returnTo?: string,
  url?: string,
): string | undefined => {
  try {
    // eslint-disable-next-line no-param-reassign
    returnTo = returnTo && atob(returnTo);
  } catch (error) {
    logger.info(
      'There was an error while trying to decode the returnTo parameter. Will continue as usual.',
      error,
    );
  }

  if (returnTo?.startsWith('//')) return undefined;

  if (!returnTo || returnTo.startsWith('/')) return returnTo;

  if (!url?.length) return undefined;

  if (!returnTo.startsWith(url)) return undefined;

  const sanitizedReturnTo = returnTo.slice(url.length);
  return sanitizedReturnTo.startsWith('/')
    ? sanitizedReturnTo
    : `/${sanitizedReturnTo}`;
};

export const attachCollinsonUserData = async (
  newSession: Session,
  market: Market,
): Promise<Session> => {
  let collinsonData = null;

  try {
    collinsonData = await getCollinsonUser(newSession, market);
  } catch (error) {
    logger.error('Failed to attach collinson user info to the user object.', {
      error: formatErrorForLogging(error),
    });
  }

  return {
    ...newSession,
    user: {
      ...newSession.user,
      collinson: collinsonData,
    },
  };
};

const handleRefreshError = async ({
  error,
  requestResponse,
}: HandleRefreshErrorParameters) => {
  logger.error(
    'There was an error while refreshing the access token. Defaulting to the current token value.',
    {
      error: formatErrorForLogging(error),
    },
  );

  if (
    error instanceof AccessTokenError &&
    error.code === AccessTokenErrorCode.FAILED_REFRESH_GRANT
  ) {
    const response = requestResponse[1];
    if ('setHeader' in response) {
      response.setHeader('Location', `${AVIOS_PREFIX}/api/auth/logout`);
      response.statusCode = StatusCodes.TEMPORARY_REDIRECT;
    }
  }
};

export const refreshSession = async ({
  session,
  market,
  requestResponse,
}: RefreshAudienceTokenParameters): Promise<void> => {
  if (!session) return;

  if (!session.refreshToken) {
    logger.warn(
      'Trying to refresh a token without refresh data. Falling back to unrefreshed token or undefined.',
    );

    return;
  }

  const authServer = getAuth0Server(market);

  try {
    logger.info('Trying to refresh token');

    const { accessToken: refreshedAccessToken } =
      await authServer.getAccessToken(...requestResponse, {
        refresh: true,
        authorizationParams: mainAudienceData,
      } as AccessTokenRequest);

    if (!refreshedAccessToken) {
      throw new Error('Data from token refresh is empty.');
    }

    logger.info('Token refreshed successfully');

    return;
  } catch (error) {
    await handleRefreshError({
      error,
      requestResponse,
    });
  }
};

export const getFormattedSilentLoginCookieFlag = (toggle: boolean) =>
  `${COOKIES.SH_SILENT_AUTH}=true;Path=/;Max-Age=${
    toggle ? SILENT_LOGIN_FLAG_EXPIRY_TIME_IN_SECONDS : 0
  }`;

export const isSilentLogin = (request: NextApiRequest) =>
  request.cookies[COOKIES.SH_SILENT_AUTH] === 'true';

export const getFormattedReturnToCookie = (returnTo: string, toggle: boolean) =>
  `${COOKIES.SILENT_LOGIN_RETURN_TO_BASE64}=${returnTo};Path=/${
    toggle ? '' : ';Max-Age=0'
  }`;

export const appSessionCookiesInRequest = (request: NextApiRequestInjected) =>
  Object.keys(request.cookies).some(cookieName =>
    cookieName.startsWith('appSession'),
  );

export const getFormattedClearedAppSessionCookies = (
  request: NextApiRequest,
) => {
  const appSessionCookieNames = Object.keys(request.cookies).filter(
    cookieName => cookieName.startsWith('appSession'),
  );

  return appSessionCookieNames.map(
    cookieName => `${cookieName}=;Path=/;Max-Age=0`,
  );
};

export const shouldRunSilentLoginForPath = (path?: string) =>
  !path ||
  !['sitemap', '_next', 'themes', 'fonts'].some(string =>
    path.includes(string),
  );

export const shouldRunSilentLoginForUserAgent = (userAgent?: string) => {
  const lowerCaseUserAgent = userAgent?.toLowerCase();

  return (
    !lowerCaseUserAgent ||
    !userAgentsSubstringToSkipSilentLogin().some(botUserAgent =>
      lowerCaseUserAgent.includes(botUserAgent),
    )
  );
};

export const shouldRunSilentLoginForAviosOpco = (
  request: NextApiRequestInjected,
  session: SessionMaybe,
) =>
  isAviosOpco(request.market.opCoId) &&
  !session &&
  appSessionCookiesInRequest(request);

export const shouldRunSilentLoginForNonAviosOpco = (
  request: NextApiRequestInjected,
  featureToggles: FeatureTogglesType,
  session: SessionMaybe,
) =>
  !isAviosOpco(request.market.opCoId) &&
  !session &&
  featureToggles.includes(FEATURE_TOGGLE_LIST.TEMP_USE_SSO_ON_PAGE_LOAD) &&
  !isSilentLogin(request);

export const mapErrorCode = (error: unknown) => {
  if (error instanceof DecodeJwtError)
    return GENERIC_ERROR_CODES.INVALID_SESSION;

  if (error instanceof CallbackHandlerError)
    return GENERIC_ERROR_CODES.CALLBACK_HANDLER_ERROR;

  return GENERIC_ERROR_CODES.UNKNOWN_ERROR;
};

export const processAuth0Handler = async ({
  handlerPromise,
  response,
  opCoId,
  returnToOnFailure,
}: ProcessAuth0HandlerParameters) => {
  try {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await handlerPromise;
  } catch (error: unknown) {
    logger.error(
      'Caught an error during auth0 handler processing',
      formatErrorForLogging(error),
    );

    const aviosPrefix = isAviosOpco(opCoId) ? AVIOS_PREFIX : '';
    return response.redirect(
      returnToOnFailure ??
        `${aviosPrefix}/error/?errorCode=${mapErrorCode(error)}`,
    );
  }
};
