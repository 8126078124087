import { useCallback, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { isAndroid, isIOS } from 'react-device-detect';
import { MODAL_CLOSE_TYPE } from '@/components/utilities/modal/modal.types';
import Modal, { closeModal, toggleModal } from '@/components/utilities/modal';
import VersatileCard from '@/components/integrated/versatile-card/versatile-card';
import {
  EVENT_NAME,
  PROMOTIONAL_MODAL_CLOSE_VARIANT,
} from '@/utils/track-event/track-event.types';
import trackEvent from '@/utils/track-event';
import timeoutHelper from '@/utils/timeout-helper';

import useBasePath from '@/hooks/use-base-path';
import {
  PromotionalModalProperties,
  PROMOTIONAL_MODAL_CLOSE_EVENT,
} from './promotional-modal.types';

const sendCloseTrackingEvent = (
  closeEvent: PROMOTIONAL_MODAL_CLOSE_EVENT | MODAL_CLOSE_TYPE,
  cookie: string,
) => {
  switch (closeEvent) {
    case MODAL_CLOSE_TYPE.OUTSIDE:
    case PROMOTIONAL_MODAL_CLOSE_EVENT.OUTSIDE: {
      trackEvent({
        event: EVENT_NAME.PROMOTIONAL_MODAL_CLOSE,
        variant: PROMOTIONAL_MODAL_CLOSE_VARIANT.OUTSIDE,
        promotion_name: cookie,
      });
      break;
    }
    case MODAL_CLOSE_TYPE.BUTTON:
    case PROMOTIONAL_MODAL_CLOSE_EVENT.BUTTON: {
      trackEvent({
        event: EVENT_NAME.PROMOTIONAL_MODAL_CLOSE,
        variant: PROMOTIONAL_MODAL_CLOSE_VARIANT.BUTTON,
        promotion_name: cookie,
      });
      break;
    }

    case PROMOTIONAL_MODAL_CLOSE_EVENT.MAIN_ACTION: {
      trackEvent({
        event: EVENT_NAME.PROMOTIONAL_MODAL_FOLLOW,
        promotion_name: cookie,
      });
      break;
    }

    default: {
      trackEvent({
        event: EVENT_NAME.PROMOTIONAL_MODAL_UNKNOWN,
        promotion_name: cookie,
      });
      break;
    }
  }
};

const PromotionalModal = ({
  id,
  versatileCardContent,
  cookie,
  showDelay,
  showForAllDevices,
  showForAndroid,
  showForIphone,
  closeLabel,
  isAvios,
}: PromotionalModalProperties) => {
  const { pathname, asPath } = useRouter();
  const { homePath } = useBasePath();
  const urlWithoutLocale = homePath.replace(/^\/[a-z]{2}-[A-Z]{2}\//, '/');

  const isHomePage = useMemo(() => {
    const isAviosHome = () => asPath === `${urlWithoutLocale}/`;
    const isStandardHome = () => pathname === '/';

    return isAvios ? isAviosHome() : isStandardHome();
  }, [pathname, asPath, isAvios]);

  const handleModalOnClose = useCallback(
    (closeEvent: PROMOTIONAL_MODAL_CLOSE_EVENT | MODAL_CLOSE_TYPE) => {
      Cookies.set(cookie, 'modalUsed', {
        expires: 7,
      });
      sendCloseTrackingEvent(closeEvent, cookie);
    },
    [cookie],
  );
  const handleModalPrimaryAction = useCallback(() => {
    closeModal(id);
    handleModalOnClose(PROMOTIONAL_MODAL_CLOSE_EVENT.MAIN_ACTION);
  }, [handleModalOnClose, id]);

  const modalCookie = Cookies.get(cookie) ?? '';

  const showPromotionalModal =
    showForAllDevices ||
    (showForAndroid && isAndroid) ||
    (showForIphone && isIOS);

  useEffect(() => {
    const executeDelayedAction = async () => {
      await timeoutHelper(showDelay);
      if (
        !modalCookie &&
        cookie &&
        Object.keys(versatileCardContent).length > 0 &&
        isHomePage
      ) {
        trackEvent({
          event: EVENT_NAME.PROMOTIONAL_MODAL_DISPLAY,
          promotion_name: cookie,
        });
        toggleModal(id);
      }
    };

    if (showPromotionalModal) {
      executeDelayedAction();
    }
  }, [pathname]);

  const versatileCardCTAs = {
    ...versatileCardContent.ctas,
    ctaFunction: handleModalPrimaryAction,
  };

  const versatileCardLinks = {
    ...versatileCardContent.links,
    linkFunction: handleModalPrimaryAction,
  };

  return (
    <Modal
      label={id}
      onCloseFunction={handleModalOnClose}
      closeOnOutsideClick
      closeLabel={closeLabel}
    >
      <VersatileCard
        {...versatileCardContent}
        ctas={versatileCardCTAs}
        links={versatileCardLinks}
      />
    </Modal>
  );
};

export default PromotionalModal;
