// TODO: remove this once the node: protocol is supported: https://github.com/webpack/webpack/issues/14166
// eslint-disable-next-line unicorn/prefer-node-protocol
import { createHash } from 'crypto';
import { tracer } from 'dd-trace';
// eslint-disable-next-line unicorn/prefer-node-protocol
import { AsyncLocalStorage } from 'async_hooks';
import type {
  ServerSideContextCacheKeyGenerator,
  ServerSideContextStore,
} from './server-side-context.types';

export const buildServerSideContextStore = (
  initialData: Omit<ServerSideContextStore, 'methodCache'>,
): ServerSideContextStore => ({
  methodCache: {},
  ...initialData,
});

const defaultCacheKeyGenerator = (...arguments_: unknown[]) =>
  createHash('sha256')
    .update(JSON.stringify(arguments_))
    .digest()
    .toString('hex');

export const createServerSideContext = () => {
  let serverSideContext: AsyncLocalStorage<ServerSideContextStore> | undefined;

  // there's no async local storage on client side
  if (globalThis.window === undefined) {
    serverSideContext = new AsyncLocalStorage<ServerSideContextStore>();
  }

  const getStore = (): ServerSideContextStore => {
    const store = serverSideContext?.getStore();
    if (!store) throw new Error('No async store found.');

    return store;
  };

  return {
    getStore,
    useContext: <R>(
      function_: () => R,
      context: Omit<ServerSideContextStore, 'methodCache'>,
    ) =>
      serverSideContext
        ? serverSideContext.run(buildServerSideContextStore(context), function_)
        : function_(),
    cacheMethod:
      (
        cacheKeyGenerator: ServerSideContextCacheKeyGenerator = defaultCacheKeyGenerator,
      ) =>
      (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        target: any,
        methodName: string,
        originalMethodProperties: PropertyDescriptor,
      ) => {
        const originalMethodBody = originalMethodProperties.value;

        // the `value` prop holds the actualy method body, we are replacing
        // the `value` prop with our own function that introduces caching
        // eslint-disable-next-line no-param-reassign
        originalMethodProperties.value = function wrappedMethod(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...arguments_: any[]
        ) {
          return tracer.trace(methodName, () => {
            const localStorage = getStore();

            const cacheKey = cacheKeyGenerator(arguments_);
            if (!localStorage.methodCache[methodName]?.[cacheKey])
              localStorage.methodCache[methodName] = {
                ...localStorage.methodCache[methodName],
                [cacheKey]: originalMethodBody.apply(this, arguments_),
              };

            return localStorage.methodCache[methodName][cacheKey];
          });
        };
      },
    getIdentityToken: () => getStore().m2mToken,
  };
};
