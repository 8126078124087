import { globalServerSideContext } from '@/utils/server-side-context';
import { OPCO_ID } from '../../models/market/market.types';
import Fetch from '../../utils/fetch';
import getGlpConfig from './glp.config';
import {
  Config,
  AccountDetailsResponse,
  PROGRAMME_IDENTIFIER_GLP,
} from './glp.types';

export default class GlpProvider extends Fetch {
  private config: Config;

  constructor() {
    const selectedConfig = getGlpConfig();

    super(selectedConfig.BASE_URL);

    this.config = selectedConfig;

    this.setHeaders();
  }

  private setHeaders() {
    this.headers = {
      api_key: this.config.API_KEY,
      'Content-Type': 'application/json',
    };
  }

  async getAccountDetails(
    membershipId: string,
    opcoID: OPCO_ID,
    showHouseHoldBalance: boolean,
  ) {
    const programmeIdentifier = PROGRAMME_IDENTIFIER_GLP[opcoID];
    const accessToken = globalServerSideContext().getIdentityToken();

    if (!accessToken) {
      return {
        hasError: true,
      };
    }

    const accountType =
      showHouseHoldBalance || [OPCO_ID.IBP, OPCO_ID.VYC].includes(opcoID)
        ? 'TOTAL_REWARD'
        : 'REWARD';

    return this.get<AccountDetailsResponse>(
      `/members/${membershipId}/programmes/${programmeIdentifier}/schemes/accounts?accountType=${accountType}`,
      { headers: { Authorization: accessToken }, cache: 'no-store' },
    );
  }
}
